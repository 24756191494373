import React from 'react'
import { withRouter } from "react-router";
import ResourceLang from '../resources/Language';
import { EnvironmentAPI } from '../utils/APIRequester';
import PageHeader from '../components/headers/PageHeader';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Tooltip, Fab } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AddIcon from '@material-ui/icons/Add';
import ConfirmationModal from '../components/modals/ConfirmationModal';
import EnvironmentModal from '../components/modals/EnvironmentModal';
import CircularLoading from '../components/loadings/CircularLoading';
import MessageSnackbar from '../components/notifications/MessageSnackbar';
import GenericCard from '../components/cards/GenericCard';
import ApartmentIcon from '@material-ui/icons/Apartment';


const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1)
    },
    contentRoot: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    toolbar: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    title: {
        flex: 1,
    },
    grid: {
        padding: theme.spacing(2)
    },
    cardRoot: {
        backgroundColor: '#f5f5f5'
    },
}));


const DashboardPage = ({match, location, history, ...props}) => {
    const classes = useStyles();
    const [state, setState] = React.useState({
        isLoading: false,
        environments: undefined,
        selectedItem: {},
        envModalOpen: undefined,
        deleteModalOpen: undefined,
        snakcbarOpen: false,
        snakcbarMsg: '',
        snakcbarVariant: 'success'
    });

    React.useEffect(() => {
        
        if (!state.environments){
            setState((prevState) => ({...prevState, isLoading: true}));

            EnvironmentAPI.getAll()
                .then(response => {
                    setState((prevState) => ({
                        ...prevState,
                        isLoading: false,
                        environments: response
                    }));
                })
                .catch(error => {
                    setState((prevState) => ({
                        ...prevState,
                        isLoading: false,
                        environments: [],
                        snakcbarOpen: true,
                        snakcbarMsg: error.message || ResourceLang.generic_error,
                        snakcbarVariant: 'error'
                    }));
                });
        }
    }, [state.environments]);


    const renderHeader = () => {
        return (<PageHeader title={ResourceLang.dashboard}
            icon={<DashboardIcon color="primary" 
                fontSize="large" />
            }
        >
            <Tooltip title={ResourceLang.add} aria-label="add">
                <Fab id="add-environment-btn"
                    color="secondary"
                    onClick={handleAddEnvironment}
                >
                    <AddIcon />
                </Fab>
            </Tooltip>
        </PageHeader>)
    }

    const handleAddEnvironment = () => {
        setState((prevState) => ({
            ...prevState,
            envModalOpen: true,
            selectedItem: {}
        }));
    }


    const renderContent = () => {
        const {environments = []} = state;

        return (<Grid container spacing={2}
            className={classes.grid}>
        {
            environments.length === 0 ? renderEmptyContent()
            : environments.map((curEnv) => {
                return(<Grid item key={curEnv.Id} xs={12} sm={6} lg={4}>
                    {renderEnvironmentCard(curEnv)}
                </Grid>);
            })
        }
        </Grid>);
    }

    const renderEmptyContent = () => {
        return (<Grid item xs={12}>
            <Typography variant="body1">
                <ApartmentIcon color="primary"/> {ResourceLang.empty_result}
            </Typography>
        </Grid>);
    }

    const renderEnvironmentCard = (env) => {
        return (
            <GenericCard
                element={env}
                hasActions={true}
                handleClick={handleEnvironmentClick}
                handleDelete={handleEnvironmentDelete}
                handleEdit={handleEnvironmentEdit}>
                    <Typography variant="h6" gutterBottom>
                        <ApartmentIcon color="primary"/> {env.Name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {env.Address}
                    </Typography>
            </GenericCard>
        )
    }

    const handleEnvironmentClick = (env) => event => {

        if (!env){
            setState((prevState) => ({
                ...prevState,
                snakcbarOpen: true,
                snakcbarMsg: ResourceLang.generic_error,
                snakcbarVariant: 'error'
            }));
            return;
        }
            

        history.push(`dashboard/environment/${env.Id}`);
    }

    const handleEnvironmentEdit = (env) => event => {

        if (!env){
            setState((prevState) => ({
                ...prevState,
                snakcbarOpen: true,
                snakcbarMsg: ResourceLang.generic_error,
                snakcbarVariant: 'error'
            }));
            return;
        }

        setState((prevState) => ({
            ...prevState,
            envModalOpen: true,
            selectedItem: env
        }));
    }

    const handleEnvironmentDelete = (env) => event => {

        if (!env){
            setState((prevState) => ({
                ...prevState,
                snakcbarOpen: true,
                snakcbarMsg: ResourceLang.generic_error,
                snakcbarVariant: 'error'
            }));
            return;
        }

        setState((prevState) => ({
            ...prevState,
            deleteModalOpen: true,
            selectedItem: env
        }));
    }



    const renderEnvironmentModal = () => {
        if (state.envModalOpen === undefined)
            return null;

        return (<EnvironmentModal open={state.envModalOpen}
            onClose={handleModalClose}
            environment={state.selectedItem}
            onChange={handleModalChange}/>);
    }

    const handleModalClose = () => {
        setState((prevState) => ({
            ...prevState,
            envModalOpen: false,
            selectedItem: {}
        }));
    }

    const handleModalChange = (success) => {
        setState((prevState) => ({
            ...prevState,
            envModalOpen: false,
            selectedItem: {},
            environments: undefined,
            snakcbarOpen: true,
            snakcbarMsg: success ? ResourceLang.changes_saved : ResourceLang.generic_error,
            snakcbarVariant: success ? 'success' : 'error'
        }));
    }


    const renderConfirmationModal = () => {
        if (state.deleteModalOpen === undefined)
            return null;

        return (<ConfirmationModal isOpen={state.deleteModalOpen}
            message={ResourceLang.delete_environment_msg}
            confirmAction={handleDeleteConfirm}
            closeModal={handleCloseDeleteModal} />);
    }

    const handleDeleteConfirm = async () => {
        const envId = state.selectedItem.Id;
        setState((prevState) => ({
            ...prevState,
            isLoading: true,
            deleteModalOpen: false,
            selectedItem: {}
        }));

        const promise = EnvironmentAPI.delete(envId);
        promise.then(response => {
                setState((prevState) => ({
                    ...prevState,
                    isLoading: false,
                    environments: undefined,
                    snakcbarOpen: true,
                    snakcbarMsg: ResourceLang.changes_saved,
                    snakcbarVariant: 'success'
                }));
            })
            .catch(error => {
                setState((prevState) => ({
                    ...prevState,
                    isLoading: false,
                    snakcbarOpen: true,
                    snakcbarMsg: ResourceLang.generic_error,
                    snakcbarVariant: 'error'
                }));
            });

        await promise;
    }

    const handleCloseDeleteModal = () => {
        setState((prevState) => ({
            ...prevState,
            deleteModalOpen: false,
            selectedItem: {}
        }));
    }


    const renderSnackbar = () => {
        return (<MessageSnackbar open={state.snakcbarOpen}
            message={state.snakcbarMsg}
            variant={state.snakcbarVariant}
            onClose={handleSnackbarClose}/>);
    }

    const handleSnackbarClose = () => {
        setState((prevState) => ({...prevState, snakcbarOpen: false}));
    }


    return (<div className={classes.root}>
        <Grid container spacing={1}>
            <Grid item xs={12}>
                {renderHeader()}
            </Grid>
            <Grid item xs={12} className={classes.contentRoot}>
                {state.isLoading ? <CircularLoading /> : renderContent()}
            </Grid>
        </Grid>

        {renderEnvironmentModal()}
        {renderConfirmationModal()}
        {renderSnackbar()}
    </div>);
}

export default withRouter(DashboardPage);