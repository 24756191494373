export function userStatus(state = false, action) {

    switch (action.type) {
        case 'CHANGE_USER_STATUS':
            return action.isLoggedIn;
        default:
            return state;
    }
}


export function userData(state = [], action) {

    switch (action.type) {
        case 'USER_DATA':
            return action.user;
        default:
            return state;
    }
}

