import React from 'react'
import PropTypes from 'prop-types';
import { Grid, ScrollSync , AutoSizer } from 'react-virtualized';
import { scrollbarSize } from '../../utils/BrowserServices';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';


const styles = (theme) => ({
    gridColumn: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto',
        height: '100%'
    },
    headerContainer: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: '4px 4px 0 0',
    },
    headerGrid: {
        width: '100%',
        overflow: 'hidden !important',
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: '4px 4px 0 0',
    },
    bodyGrid: {
        width: '100%',
    },
    headerCell: {
        border: 'none',
        fontSize: '0.8rem',
        padding: theme.spacing(1),
        color: 'white',
        backgroundColor: theme.palette.primary.main,
    },
    bodyCell: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderLeft: '1px solid rgba(224, 224, 224, 1)',
        fontSize: '0.75rem',
        padding: theme.spacing(1),
    },
    truncateText: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    evenRow: {
        backgroundColor: '#ffffff',
    },
    oddRow: {
        backgroundColor: '#eeeeee',
    },
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
    },
});


const RawVirtualizedGrid = ({
    rowCount, columns, columnWidth = 200, rowHeight = 40, overscanRowCount = 10, rowGetter, classes
}) => {
    const headerCellRenderer = ({columnIndex, key, rowIndex, style, ...ohter}) => {
        const column = columns[columnIndex];
        const label = column.label || column.dataKey;
        
        return (<TableCell variant="body" component="div"
            key={key} style={style}
            className={[classes.headerCell, classes.flexContainer].join(' ')}
        >
           <b className={classes.truncateText}>{label}</b>
      </TableCell>);
    }

    const bodyCellRenderer = ({columnIndex, key, rowIndex, style, ...ohter}) => {
        const rowData = rowGetter(rowIndex);
        const column = columns[columnIndex];

        let cellData = rowData[column.dataKey] || "-";
        if(column.dataKey ==="#"){
            cellData = (<>{rowIndex + 1}</>);
        }
        else if (column.render) {
            cellData = column.render(rowData);
        }
        else if(cellData instanceof Date){
            cellData = `${cellData.toLocaleDateString()} ${cellData.toLocaleTimeString()}`
        }
        else if(typeof(cellData) === 'number') {
            if (cellData && !isNaN(cellData)) {
                cellData = Number(Math.round(cellData))
            }
            else
                cellData = "-";
        }
        else if (typeof(cellData) === 'object') {
            cellData = JSON.stringify(cellData);
        }

        return (<TableCell component="div" key={key} title={cellData} style={style}
            className={[classes.bodyCell, classes.flexContainer,
                (rowIndex % 2 ? classes.evenRow : classes.oddRow)].join(' ')}
            variant="body"
        >
          <span className={classes.truncateText}>{cellData}</span>
      </TableCell>);
    }


    const renderGrid = ({clientHeight, clientWidth,
        onScroll, scrollHeight, scrollLeft, scrollTop, scrollWidth,
      }) => {
        return (<div className={classes.gridColumn}>
            <AutoSizer>
            {({width, height}) => {
                const scrollBarSize = scrollbarSize();
                const headerRowHeight = rowHeight;
                return (<>
                    <div className={classes.headerContainer}
                        style={{width: width, marginRight: scrollBarSize}}>
                        <Grid
                            className={classes.headerGrid}
                            columnWidth={columnWidth}
                            columnCount={columns.length}
                            height={rowHeight}
                            cellRenderer={headerCellRenderer}
                            rowHeight={headerRowHeight}
                            rowCount={1}
                            scrollLeft={scrollLeft}
                            width={width - scrollBarSize}/>
                    </div>
                    <Grid
                        className={classes.bodyGrid}
                        columnWidth={columnWidth}
                        columnCount={columns.length}
                        height={height - headerRowHeight}
                        onScroll={onScroll}
                        overscanRowCount={overscanRowCount}
                        cellRenderer={bodyCellRenderer}
                        rowHeight={rowHeight}
                        rowCount={rowCount}
                        width={width}/>
                </>);
            }}
            </AutoSizer>
        </div>);
    }


    return (<ScrollSync>
        {(scrollProps) => renderGrid(scrollProps)}
    </ScrollSync>);
}


RawVirtualizedGrid.propTypes = {
    rowCount: PropTypes.number.isRequired,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
          dataKey: PropTypes.string.isRequired,
          label: PropTypes.string,
          render: PropTypes.func,
          decimalPlaces: PropTypes.number, //if typeof(value) number
        }),
    ).isRequired,
    columnWidth: PropTypes.number,
    rowHeight: PropTypes.number,
    overscanRowCount: PropTypes.number,
    rowGetter: PropTypes.func.isRequired,
};

export default withStyles(styles)(RawVirtualizedGrid);