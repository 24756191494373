import React from 'react';
import PropTypes from 'prop-types';
import { ResourceLang } from '../../resources/Language';
import { EnvironmentAPI } from '../../utils/APIRequester';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Grid, Button } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import MuiInputText from '../inputs/MuiInputText';


const DEFAULT_STATE = {
    isLoading: false,
    environment: {},
    errors: {}
};


const EnvironmentModal = ({open, onClose, environment, onChange}) => {
    const [state, setState] = React.useState({...DEFAULT_STATE});
    const env = state.environment;


    React.useEffect(() => {
        setState({...DEFAULT_STATE, environment: {...environment}});
    }, [environment]);


    const renderBody = () => {
        return (<Grid container spacing={2}>
            <Grid item xs={12}>
                <MuiInputText name={"Name"}
                    variant="standard"
                    value={env.Name || ""}
                    error={!!state.errors['Name']}
                    helperText={state.errors['Name'] || undefined}
                    label={ResourceLang.name}
                    onChange={onPropertyChange}
                    required={true}
                    maxLength={100}/>
            </Grid>
            <Grid item xs={12}>
                <MuiInputText name={"Address"}
                    variant="standard"
                    value={env.Address || ""}
                    label={ResourceLang.address}
                    onChange={onPropertyChange}
                    required={true}
                    maxLength={100}/>
            </Grid>
            <Grid item xs={12}>
                <MuiInputText name={"ApiKey"}
                    variant="standard"
                    value={env.ApiKey || ""}
                    label={ResourceLang.api_key}
                    onChange={onPropertyChange}
                    required={true}
                    maxLength={100}/>
            </Grid>
        </Grid>);
    }

    const onPropertyChange = (name, value, error) => {
        setState((prevState) => {
            const newState = {...prevState}
            newState.environment[name] = value;
            return newState;
        });
    };

    const handleClose = () => {
        onClose();
    }

    const handleSubmit = async () => {
        setState((prevState) => ({...prevState, isLoading: true}));
        
        const isCreate = (!env.Id || env.Id <= 0);
        const promise = (isCreate ?
            EnvironmentAPI.create(env) : EnvironmentAPI.update(env.Id, env));

        promise.then(response => {
                onChange(true);
            })
            .catch(handleError);
        
        await promise;
    }

    const handleError = (error) => {
        if (error.data) {
            const {Error} = error.data;
            const errors = {};

            switch(Error) {
                case 'name_is_not_unique':
                    errors['Name'] = ResourceLang.non_unique_error;
                    break;

                default: break;
            }

            if (Object.keys(errors).length > 0){
                setState((prevState) => ({
                    ...prevState,
                    isLoading: false,
                    errors: errors
                }));
                return;
            }
        }

        onChange(false);
    }


    return (<Modal size="xs" isOpen={open} 
        backdrop="static" centered
    >
        <ModalHeader toggle={handleClose}>
            {ResourceLang.environment}
        </ModalHeader>
        <ModalBody>
            {renderBody()}
        </ModalBody>
        <ModalFooter>
            <Button size="small"
                startIcon={<CloseIcon />}
                onClick={handleClose}
            >
                {ResourceLang.close}
            </Button>
            <Button size="small"
                startIcon={<CheckIcon />}
                onClick={handleSubmit}
            >
                {ResourceLang.submit}
            </Button>
        </ModalFooter>
    </Modal>);
}


EnvironmentModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    environment: PropTypes.shape({
        Id: PropTypes.number,
        Name: PropTypes.string,
        Address: PropTypes.string,
        ApiKey: PropTypes.string
    }).isRequired,
    onChange: PropTypes.func
}

export default EnvironmentModal;