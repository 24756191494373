import React from 'react';
import CacheBuster from './utils/CacheBuster';
import AppProvider from './redux/store/AppProvider';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiTheme } from './components/themes/MuiTheme';


function App() {
  return(<CacheBuster>
    {({ loading, isLatestVersion, refreshCacheAndReload }) => {
      if (loading)
        return null;

      if (!loading && !isLatestVersion) {
        // You can decide how and when you want to force reload
        refreshCacheAndReload();
      }

      return (
      <ThemeProvider theme={MuiTheme}>
        <AppProvider/>
      </ThemeProvider>);
    }}
  </CacheBuster>);
}

export default App;
