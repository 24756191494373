import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import {makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ResourceLang from '../../resources/Language';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  body_content:{
    marginTop: 30,
    textAlign: 'center'
  }
}));


const SelectVersions = ({availableVersions, versionSelected}) => {
    const classes = useStyles();
    const [versionChoosen, setVersionChoosen] = React.useState('');
    const [open, setOpen] = React.useState(false);

    const renderContent = () => {
        const handleChange = (event) => {
          setVersionChoosen(event.target.value)
          versionSelected(availableVersions[event.target.value].current_firmware_version)
        };
      
        const handleClose = () => {
          setOpen(false);
        };
      
        const handleOpen = () => {
          setOpen(true);
        };

        const renderVersionChoosenOnDropDown = () =>{
          return(
            <InputLabel id="demo-controlled-open-select-label">{ResourceLang.version}</InputLabel>
          )
        }

        return(<div>
          <FormControl className={classes.formControl}>
            {renderVersionChoosenOnDropDown}
            <Select labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              value={versionChoosen}
              onChange={handleChange}>
              {availableVersions.map((x,y) => <MenuItem key={y} value={y}>{x.current_firmware_version}</MenuItem>)}
            </Select>
          </FormControl>
        </div>
        )
    }

    return(
        <Grid container spacing={2}>
            <Grid item lg={11} xs={12} style={{height: 150}}>
              <div className={classes.body_content}>
                <Typography>
                  {ResourceLang.update_version_choose} 
                </Typography>
                {renderContent()}
              </div>
            </Grid>
        </Grid>
    )
}

SelectVersions.propTypes = {
    availableVersions: PropTypes.object.isRequired,
    versionSelected: PropTypes.func.isRequired
};

export default SelectVersions;