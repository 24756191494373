import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ResourceLang from '../../../resources/Language';
import InputPassword from '../../../components/inputs/InputPassword';
import {FirmwareAPI} from '../../../utils/APIRequester';
import { OvalButtonPrimary, useStyles as ovalByttonsStyleBase } from '../../../components/buttons/OvalButtons';
import { CircularButtonTransparent } from '../../../components/buttons/CircularButtons';
import { makeStyles } from '@material-ui/core/styles';
import { Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignal } from '@fortawesome/free-solid-svg-icons';
import {SETTINGS_TYPE_ENUM }  from '../../../utils/Enums';
import EditIcon from '@material-ui/icons/Edit';
import DeviceSettingsDialog from '../../../components/dialogs/add/DeviceSettingsDialog';
import MessageSnackbar from '../../../components/notifications/MessageSnackbar';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/NotInterested';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
    paper: {
       padding: theme.spacing(3),
       margin: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(1),
        float:'right'
    },
    label_title: {
        color: "#94A3A7",
        fontWeight: 'bold',
        marginBottom: theme.spacing(0)
    },
    divider:{
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    }
}));


const APNSettings = ({
    envId, interfaceGPRS, deviceItemId
}) => {
    const classes = useStyles();
    const ovalButtonsStyle = ovalByttonsStyleBase();
    const [editMode, setEditMode] = useState(false);
    const [openApplyToDialog, setApplyToDialog] = useState(false);
    const [ snackbar, setSnackbar] = useState({ open: false, variant: 'error', message: ''});
    const [data, setData] = useState(interfaceGPRS);
    
    const readOnly = editMode ? '' : 'readonly';


    const handleEditClick = () => {
        setEditMode(!editMode);
    }

    const renderPanelHeader = () => {
        return (<Row>
            <Col>
                <Typography 
                    variant="subtitle1" 
                    gutterBottom
                    className={classes.label_title}>
                        {ResourceLang.Apn_Settings} <FontAwesomeIcon icon={faSignal} />
                </Typography>
            </Col>
            <Col style={{textAlign: 'end'}}>
                <Tooltip title="Edit" placement="top">
                    <CircularButtonTransparent size="small" aria-label="edit" onClick={handleEditClick}>
                        <EditIcon />
                    </CircularButtonTransparent>
                </Tooltip>
            </Col>
        </Row>);
    }

    const renderAuthInput = () => {
        const AUTH_OPTIONS = [
            {label: ResourceLang.NONE, value: 0},
            {label: ResourceLang.PAP, value: 1},
            {label: ResourceLang.CHAP, value: 2},
            {label: ResourceLang.PAP_OR_CHAP, value: 3},
        ]

        if(editMode) {
            return(<Input 
                bsSize="sm"
                type="select"
                value={data.apn_auth_type}
                onChange={event =>{
                    const value = parseInt(event.target.value, 10);
                    setData({...data, apn_auth_type: value});
                }}>
                {
                    AUTH_OPTIONS.map(({value, label}) => 
                        <option key={value} value={value}>{label}</option>    
                    )
                }
            </Input>);
        }
        else{
            let value = ResourceLang.NONE;

            if (data.apn_auth_type) {
                const target = AUTH_OPTIONS.find(x => x.value === data.apn_auth_type);
                if (target)
                    value = target.label;
            }

            return(
                <Typography variant="subtitle2" gutterBottom>
                    {value}
                </Typography>
            );
        }
    }

    const renderNameField = () => {
        if (editMode === true)
            return (
            <Input bsSize="sm" readOnly={readOnly}
                type="text" invalid={!data.apn_name}
                value={data.apn_name} maxLength="50"
                onChange={(event)=>{
                setData({...data, apn_name: event.target.value});
                }}/>
            )
        else
        return (
            <Typography variant="subtitle2" gutterBottom>
                {data.apn_name}
            </Typography>
        )

    }

    const renderUserField = () => {
        if (editMode === true)
            return (
                <Input bsSize="sm" readOnly={readOnly}
                type="text" invalid={!data.apn_user}
                value={data.apn_user} maxLength="30"
                onChange={(event)=>{
                setData({...data, apn_user: event.target.value});
                }}/>
            )
        else
        return(
            <Typography variant="subtitle2" gutterBottom>
                {data.apn_user}
            </Typography>
            )
    }

    const renderPasswordField = () =>{
        let Password_Chars = "";
        if (editMode === true)
            return (
                <InputPassword bsSize="sm" readOnly={readOnly}
                invalid={!data.apn_pwd} value={data.apn_pwd} 
                maxLength="30" onChange={(event)=>{
                setData({...data, apn_pwd: event.target.value});
                }}/>
            )
        else
            Password_Chars="";
            for(let index = 0;index<data.apn_pwd.length;index++)
            {
                Password_Chars = Password_Chars + '*';
            }
            return(
                <Typography variant="subtitle2" type="password" gutterBottom>
                    {Password_Chars}
                </Typography>
            )
    }

    const renderICCIDField = () => {
        return(
            <Typography variant="subtitle2" gutterBottom>
                {data.iccid ? data.iccid : ResourceLang.not_applicable}
            </Typography>
            )
    }

    const renderCurrentIPField = () => {
        return(
            <Typography variant="subtitle2" gutterBottom>
                {data.current_ip ? data.current_ip : ResourceLang.not_applicable}
            </Typography>
            )
    }

    const renderDialogApplyTo = () =>{
        var UpdateData = []
        UpdateData.push(data)
        return(<DeviceSettingsDialog isOpen={openApplyToDialog}
            closeModal={(e) => setApplyToDialog(false)}
            settingsType={SETTINGS_TYPE_ENUM.APN}
            envId={envId}
            deviceItemId={deviceItemId}
            dataToBeUpdated={UpdateData}/>
        )
    }

    const enableDialogApplyTo = () =>{
        setApplyToDialog(true)
    }

    const renderPanelFields = () => {
        return (<>
            <Row>
                <Col md="3">
                    <FormGroup>
                        <Label size="sm"
                            className={classes.label_title}> 
                                {ResourceLang.name} *
                        </Label>
                        {renderNameField()}
                    </FormGroup>
                </Col>
                <Col md="3">
                    <FormGroup>
                        <Label size="sm"
                            className={classes.label_title}> 
                                {ResourceLang.Auth} *
                        </Label>
                        {renderAuthInput()}
                    </FormGroup>
                </Col>
                <Col md="3">
                    <FormGroup>
                        <Label size="sm"
                            className={classes.label_title}> 
                                {ResourceLang.ICCID}
                        </Label>
                        {renderICCIDField()}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
            <Col md="3">
                    <FormGroup>
                        <Label size="sm"
                            className={classes.label_title}> 
                                {ResourceLang.User} *
                        </Label>
                        {renderUserField()}
                    </FormGroup>
                </Col>
                <Col md="3">
                    <FormGroup>
                        <Label size="sm"
                            className={classes.label_title}> 
                                {ResourceLang.password} *
                        </Label>
                        {renderPasswordField()}
                    </FormGroup>
                </Col>
                <Col md="3">
                    <FormGroup>
                        <Label size="sm"
                            className={classes.label_title}> 
                                {ResourceLang.CurrentIP} 
                        </Label>
                        {renderCurrentIPField()}
                    </FormGroup>
                </Col>
                {!editMode ? 
                <Col md="12">
                    <Button variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={enableDialogApplyTo}>
                        {ResourceLang.apply_to}
                    </Button>
                </Col> : null}
                
            </Row>
        </>)
    }

    const handleSnackbarClose = () =>{
        setSnackbar((prevState) => ({...prevState, 
            open: false, 
        }));
    }

    const renderSnackbar = () => {
        return (<MessageSnackbar open={snackbar.open}
            message={snackbar.message}
            onClose={handleSnackbarClose}
            variant={snackbar.variant}
        />);
      }

    const renderButtons = () => {
        if(editMode === false)
            return null;
        
        return(<Row>
            <Col>
                <OvalButtonPrimary size="medium"
                    variant="extended"
                    color="primary"
                    aria-label="add"
                    className={ovalButtonsStyle.submitButton}
                    onClick={() => {
                        const formData = new FormData();
                        formData.append('gprs', JSON.stringify(data));
                        FirmwareAPI.putGPRSSettingsByDevice(envId, deviceItemId, formData)
                        .then(response => {
                            setEditMode(false);
                            setSnackbar((prevState) => ({...prevState, 
                                open: true,
                                variant: "success",
                                message: ResourceLang.update_with_success
                            }));
                        }).catch(error => {
                            setEditMode(true);
                            setSnackbar((prevState) => ({...prevState, 
                                open: true,
                                variant: "success",
                                message: ResourceLang.update_with_success
                            }));
                        })
                    }}>
                        <CheckIcon className={ovalButtonsStyle.extendedIcon} />
                        {ResourceLang.submit}
                </OvalButtonPrimary>
                <OvalButtonPrimary size="medium"
                    variant="extended"
                    color="primary"
                    aria-label="add"
                    className={ovalButtonsStyle.cancelButton}
                    onClick={() => {
                        setData({...interfaceGPRS});
                        setEditMode(false);
                    }}>
                        <CancelIcon className={ovalButtonsStyle.extendedIcon} />
                        {ResourceLang.cancel}
                </OvalButtonPrimary>
            </Col>
        </Row>);
    }
    
    return(<Paper elevation={3}
        className={classes.paper}>
            {renderPanelHeader()}
            <Divider className={classes.divider}/>
            {renderPanelFields()}
            {renderButtons()}
            {renderDialogApplyTo()}
            {renderSnackbar()}
    </Paper>);
}


APNSettings.propTypes = {
    interfaceGPRS: PropTypes.shape({
        apn_name: PropTypes.string.isRequired,
        apn_auth_type: PropTypes.number.isRequired,
        apn_user: PropTypes.string.isRequired,
        apn_pwd: PropTypes.string.isRequired,
        iccid: PropTypes.string,
        current_ip: PropTypes.string
    }).isRequired
}

export default APNSettings