import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {ResourceLang} from '../../resources/Language';
import {Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label} from 'reactstrap'; 
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';

const styles = makeStyles(theme => ({
    warningClass: {
        color: 'orange'
    },
    buttonConfirm: {
        color: '#2C4153'
    },
    buttonCancel: {
        marginLeft: theme.spacing(1)
    }
}));


const ConfirmationModal = props => {
    const classes = styles();
    const state = {
        message: props.message || "",
        warning: props.warning || undefined,
        critialToken: (props.critialToken || "").replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    };
    const [isValid, setValid] = useState(() => (state.critialToken === ""));

    
    const onTokenInputChange = (event) => {
        const isTokenValid = (state.critialToken === event.target.value);
        
        if(isValid !== isTokenValid)
            setValid(isTokenValid);
    }

    const onConfirmClick = () => {
        props.confirmAction();
    }

    const handleToggle = () => {
        props.closeModal();
    }


    const renderWarning = () => {
        if (state.warning !== undefined) {
            return (<Row style={{marginTop: 10, marginBottom: 10}}>
                <Col xs="2" style={{paddingRight: 0, textAlign: 'center'}}>
                    <WarningIcon className={classes.warningClass}
                    style={{height: 48, width: 48}}/>
                </Col>
                <Col>
                    <Label>{state.warning}</Label>
                </Col>
            </Row>);
        }

        return null;
    }

    const renderCriticalToken = () => {
        if (state.critialToken !== "") {
            return (<>
                <Label>
                    {ResourceLang.ConfirmationMessage} <span style={{fontWeight: 'bold'}}>
                    {state.critialToken}</span>
                </Label>
                <Input invalid={!isValid}
                    bsSize="sm" type='text'
                    onChange={onTokenInputChange}>
                </Input>
            </>);
        }

        return null;
    }


    return (<Modal size="xs" isOpen={props.isOpen}
        backdrop="static" centered
    >
        <ModalHeader toggle={handleToggle}>
            {ResourceLang.confirmation_required}
        </ModalHeader>
        <ModalBody>
            <Label>{state.message}</Label>
            {renderWarning()}
            {renderCriticalToken()}
        </ModalBody>
        <ModalFooter>
            <Button size="small"
                className={classes.buttonCancel}
                onClick={handleToggle}
                startIcon={<CloseIcon />}
            >
                {ResourceLang.cancel}
            </Button>
            <Button size="small"
                className={classes.buttonConfirm}
                onClick={onConfirmClick}
                disabled={(isValid === true ? false  : true)}
                startIcon={<CheckIcon />}
            >
                    {ResourceLang.confirm}
            </Button>
        </ModalFooter>
    </Modal>);
}


ConfirmationModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    message: PropTypes.string,
    warning: PropTypes.string,
    critialToken: PropTypes.string,
    confirmAction: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired
}

export default ConfirmationModal;