import React from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { DialogTitle as MuiDialogTitle , DialogContent as MuiDialogContent,
    DialogActions as MuiDialogActions, IconButton, Typography, Tooltip,
    Dialog as MuiDialog} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});
  

const DialogTitle = withStyles(styles)(({children, classes, onClose, closeLabel = 'Close', ...other}) => {
    return (<MuiDialogTitle disableTypography
        className={classes.root} {...other}
    >
        <Typography variant="h6">{children}</Typography>
        <Tooltip title={closeLabel} aria-label={closeLabel}>
            <IconButton aria-label="close"
                className={classes.closeButton}
                onClick={onClose}
            >
                <CloseIcon />
            </IconButton>
        </Tooltip>
    </MuiDialogTitle>);
});


const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
}))(MuiDialogActions);


const Dialog = ({fullWidth = true, disableBackdropClick = true, disableEscapeKeyDown = true,
    onClose, closeLabel, titleRenderer, actionsRenderer, children, ...other
}) => {
    return (<div>
        <MuiDialog onClose={onClose} {...other}
            fullWidth={fullWidth}
            disableBackdropClick={disableBackdropClick}
            disableEscapeKeyDown={disableEscapeKeyDown}>
            <DialogTitle onClose={onClose} closeLabel={closeLabel}>
                {titleRenderer()}
            </DialogTitle>
            <DialogContent dividers>
                {children}
            </DialogContent>
            {actionsRenderer ?(
                <DialogActions>
                    {actionsRenderer()}
                </DialogActions>) : null
            }
        </MuiDialog>
    </div>);
}


Dialog.propTypes = {
    open: PropTypes.bool.isRequired,
    fullWidth: PropTypes.bool,
    disableBackdropClick: PropTypes.bool,
    disableEscapeKeyDown: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    closeLabel: PropTypes.string,
    titleRenderer: PropTypes.func.isRequired,
    actionsRenderer: PropTypes.func
};

export default Dialog;