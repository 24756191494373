export const ResourceLang = {
  device_firmware_table: 'Devices found',
  available_versions_table: 'Available versions and creation date',
  add: 'Add',
  address: 'Address',
  all: 'ALL',
  Apn_Settings: 'APN Settings',
  apply_to: 'Apply To...',
  api_key: 'Api-Key',
  Auth: 'Auth',
  available_versions: 'Available Versions',
  back: 'back',
  cancel: 'Cancel',
  changes_saved: 'Changes saved.',
  CHAP: 'CHAP',
  clean: 'Clean',
  close: 'Close',
  confirm: 'Confirm',
  confirmation_required: 'Confirmation Required',
  cuh_label: 'Current Harmonics',
  cnt_label: 'Connection Test',
  creation_date: 'Creation Date',
  CurrentIP: 'Current IP',
  update_version: 'Update Version',
  dashboard: 'Dashboard',
  data_update_apn: 'Update APN Settings',
  data_update_ntp: 'Update NTP Settings',
  deepgrid: 'DEEPGRID',
  delete: 'Delete',
  delete_environment_msg: 'Delete this environment?',
  device: 'Device',
  device_settings_update: 'Device Settings Update',
  device_to_be_upgraded: 'Devices to be upgraded',
  device_to_be_updated: 'Devices to be updated',
  device_updated_with_success: 'Device updated with success.',
  devices: 'Devices',
  earth_label: 'Earthing',
  edit: 'Edit',
  email: 'Email',
  empty_result: 'There are no items.',
  enb_label: 'Energy Balance',
  eneida_backoffice: 'eneida BackOffice',
  environment: 'Environment',
  export: 'EXPORT',
  filters: 'Filters',
  get_devices: 'Get Devices',
  generic_error:
    'Something went wrong. Please try again, if persists contact the administrator.',
  htp: 'HTP',
  keep_me_logged: 'Remember Me',
  hardware_version: 'Hardware Version',
  ICCID: 'ICCID',
  MSP: 'MSP',
  PIC: 'PIC',
  MEA: 'MEA',
  COM: 'COM',
  firmware_id: 'Firmware Id',
  current_version: 'Current Version',
  update_version: 'Update Version',
  items: 'Items',
  last_communication: 'Last Communication',
  list_device: 'List Device',
  loading: 'Loading...',
  logout: 'Logout',
  login: 'Login',
  micro_controller: 'Micro Controller:',
  micro_controller_info: 'Please select in your left menu a micro controller',
  missing_password: 'Enter password',
  missing_username: 'Enter email',
  name: 'Name',
  next_version: 'Next Version to Update: ',
  ntp: 'NTP',
  NONE: 'None',
  no_versions: 'No available versions were found.',
  no_devices: 'No available devices were found.',
  not_applicable: 'N/A',
  npq_label: 'Network Power Quality',
  ntp_settings: 'NTP Settings',
  normal: 'Normal',
  non_unique_error: "It's already in use",
  PAP: 'PAP',
  PAP_OR_CHAP: 'PAP or CHAP',
  password: 'Password',
  review_and_confirmation: 'Review and confirmation',
  select_devices: 'Select Devices',
  serial_number: 'Serial Number',
  submit: 'Submit',
  timeserver_type: 'Timeserver type',
  timeserver_type_deepgrid: 'Deepgrid',
  timeserver_type_htp: 'HTP',
  timeserver_type_ntp: 'NTP',
  timeserver_protocol: 'Timeserver protocol',
  timeserver_url: 'Timeserver url',
  timeserver_port: 'Timeserver port',
  total_devices: 'Total Devices',
  type: 'Type',
  unknowm: 'Unknowm',
  update_firmware: 'Update Firmware',
  update_version_choose: 'Choose your upgrade version: ',
  update_request_date: 'Update Request Date',
  upload: 'Upload',
  update_devices_with_success: 'Devices updated with success!',
  upload_dropzone_area_firmware_message:
    'Drag and drop your Firmware file here or click',
  upload_firmware: 'Upload Firmware',
  upload_error_file_size_message:
    'The file can not be empty or have more than 4 MB.',
  upload_error_no_file: 'You have to select one file.',
  upload_success_message: 'The file was successfully uploaded.',
  update_with_success: 'Device updated with success!',
  User: 'User',
  username_password_incorrect: 'The email or password is incorrect.',
  version: 'Version',
  version_choose: 'Version Choose',
  waitting_for_devices:
    'Please wait until the operation be completed. Waitting for devices.',
  invalid_fw_version: 'Invalid version',
}

export default ResourceLang
