export const isMobile = () => {
    try { return ('ontouchstart' in document.documentElement); }
    catch { return false; }
}

export const bytesToSize = (bytes, decimals = 2) => {
    if (!bytes || bytes < 0)
        return '';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}


let size;
export const scrollbarSize = (recalc) => {
  if ((!size && size !== 0) || recalc) {
    const canUseDOM = !!(
        typeof window !== 'undefined' &&
        window.document &&
        window.document.createElement
    );

    if (canUseDOM) {
      const scrollDiv = document.createElement('div');

      scrollDiv.style.position = 'absolute';
      scrollDiv.style.top = '-9999px';
      scrollDiv.style.width = '50px';
      scrollDiv.style.height = '50px';
      scrollDiv.style.overflow = 'scroll';

      document.body.appendChild(scrollDiv);
      size = scrollDiv.offsetWidth - scrollDiv.clientWidth;
      document.body.removeChild(scrollDiv);
    }
    else {
        size = 14;
    }
  }

  return size;
}


export const compareValues = (x, y) => {
  if (typeof(x) === 'string')
      return x.localeCompare(y);
  else
    return (x === y ? 0 : (x < y ? -1 : 1));
}


export const handleBodyResize = () => {
  const navbar = document.getElementById('app-navbar');
  const navbarHeight = (navbar ? (navbar.clientHeight + 1) : 52);

  const height = (window.innerHeight - navbarHeight) + "px";
  const element = document.getElementById("root");
  element.style.height = height;
}