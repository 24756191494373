import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import FilterListIcon from '@material-ui/icons/FilterList'
import FilterPopover from '../filters/FilterPopover'
import Tooltip from '@material-ui/core/Tooltip'
import { IconButton } from '@material-ui/core'
import ResourceLang from '../../resources/Language'

const styles = () => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    height: '2rem',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    border: '1px solid rgba(0, 0, 0, 0.15)',
    borderRadius: '0.5vh',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '14px',
  },
  leftCell: {
    marginLeft: '1.5rem',
    marginRight: 'auto',
  },
  rightCell: {
    marginRight: '1.5rem',
    marginLeft: 'auto',
  },
})

const TableHeader = ({
  tableTitle,
  filters,
  anchorEl,
  updateAnchorEl,
  setFilters,
  classes,
  showFilters,
}) => {
  return (
    <>
      <div className={classes.header}>
        <div className={classes.leftCell}>
          <span className={classes.title}>{tableTitle}</span>
        </div>
        {showFilters === false ? (
          <></>
        ) : (
          <>
            <div className={classes.rightCell}>
              <Tooltip title={ResourceLang.filters} aria-label='filter'>
                <IconButton
                  id='btn_filter'
                  onClick={(event) => updateAnchorEl(event.currentTarget)}
                >
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
              <FilterPopover
                filters={filters}
                anchorEl={anchorEl}
                updateAnchorEl={updateAnchorEl}
                onChange={setFilters}
              />
            </div>
          </>
        )}
      </div>
    </>
  )
}

TableHeader.propTypes = {
  tableTitle: PropTypes.string,
  filters: PropTypes.array.isRequired,
  anchorEl: PropTypes.oneOfType([
    PropTypes.instanceOf(Element),
    PropTypes.func,
  ]),
  updateAnchorEl: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  showFilters: PropTypes.bool,
}

export default withStyles(styles)(TableHeader)
