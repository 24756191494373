
import MuiVirtualizedTable from '../../../components/tables/MuiVirtualizedTable';
import ResourceLang from '../../../resources/Language';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';

const APNSettings = ({settings}) => {

    const DEVICE_SETTINGS_KEYS = {
        APN_NAME: 'apn_name',
        APN_USER:'apn_user',
        APN_PWD: 'apn_pwd',
        APN_AUTH_TYPE: 'apn_auth_type' 
    }

    const translatedSettings = cloneDeep(settings);

    function translateApnAuthType(translatedSettings){
      if(translatedSettings.apn_auth_type === 1){
        translatedSettings.apn_auth_type = ResourceLang.PAP
      }
      else if (translatedSettings.apn_auth_type === 2)
      {
        translatedSettings.apn_auth_type = ResourceLang.CHAP
      }
      else if (translatedSettings.apn_auth_type === 3)
      {
        translatedSettings.apn_auth_type = ResourceLang.PAP_OR_CHAP
      }
    }

    function translatePwd(translatedSettings){
      let password_chars="";
        for(let index = 0;index<translatedSettings.apn_pwd.length;index++)
        {
          password_chars = password_chars + '*';
        }
        translatedSettings.apn_pwd = password_chars;
    }

    function translateSettingsToTableRender(translatedSettings){
      if(translatedSettings.apn_auth_type !== undefined){
        translateApnAuthType(translatedSettings)
      }
      if(translatedSettings.apn_pwd !== undefined){
        translatePwd(translatedSettings)
      }
    }

    const renderSettingsTable = () => {
        if (translatedSettings !== undefined) {
          translateSettingsToTableRender(translatedSettings[0])
          const columns = [
            {
                columnWidth: 150,
                label: ResourceLang.name,
                dataKey: DEVICE_SETTINGS_KEYS.APN_NAME,
                disableSort: false,
            },
            {
                columnWidth: 75,
                label: ResourceLang.User,
                dataKey: DEVICE_SETTINGS_KEYS.APN_USER,
                disableSort: false,
            },
            {
                columnWidth: 75,
                label: ResourceLang.password,
                dataKey: DEVICE_SETTINGS_KEYS.APN_PWD,
                disableSort: false,
            },
            {
                columnWidth: 100,
                label: ResourceLang.Auth,
                dataKey: DEVICE_SETTINGS_KEYS.APN_AUTH_TYPE,
                disableSort: false
            }
          ];
    
          return (<MuiVirtualizedTable
            totalRows={translatedSettings.length}
            rowCount={translatedSettings.length}
            rowGetter={({ index }) => translatedSettings[index]}
            sortBy={DEVICE_SETTINGS_KEYS.APN_NAME}
            sortDirection={'ASC'}
            columns={columns}
          />);
        }
        else {
          return (<></>)
        }
    
      }

    return(<>{renderSettingsTable()}</>)

}

APNSettings.propTypes = {
  settings: PropTypes.array.isRequired
};

export default APNSettings; 