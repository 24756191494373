import jwt from 'jwt-decode';

const AUTH_TOKEN = "authorizationToken";
const IS_AUTH = "isAuth";
const KEEP_LOGGED = "keepLogged";
const AUTH_USERNAME = "authorizationUsername";
const REDIRECT_TO = "redirectTo";

const SessionServices = {
    getAuthToken: () => {
        return localStorage.getItem(AUTH_TOKEN);
    },
    getKeepLogged: () => {
        const value = localStorage.getItem(KEEP_LOGGED);
        return (value ? (value === 'true' || value === true) : false);
    },
    getUsername: () => {
        return sessionStorage.getItem(AUTH_USERNAME);
    },
    isAuth: () => {
        const value = sessionStorage.getItem(IS_AUTH);
        return (value ? (value === 'true' || value === true) : false);
    },

    setAuthContext: (authToken, username, keepLogged = false) => {
        localStorage.setItem(AUTH_TOKEN, authToken);
        localStorage.setItem(KEEP_LOGGED, keepLogged);
        sessionStorage.setItem(AUTH_USERNAME, username);
        sessionStorage.setItem(IS_AUTH, true);
    },
    cleanAuthContext: () => {
        localStorage.clear();
        sessionStorage.clear();
    },

    getSessionData(){
        const token = this.getAuthToken();

        if(token) {
            try {
                const decoded = jwt(token);
                if (decoded) {
                    decoded.isExpired = () => (this.exp < Date.now() / 1000);
                }

                return decoded;
            }
            catch(error){ console.log(error); }
        }

        return undefined;
    },

    saveRedirectTo: (value) => {
        sessionStorage.setItem(REDIRECT_TO, value);
    },
    getRedirectTo: () => {
        return sessionStorage.getItem(REDIRECT_TO) || '';
    },
    cleanRedirectTo: () => {
        sessionStorage.removeItem(REDIRECT_TO);
    }
}

export default Object.freeze(SessionServices);