import { createMuiTheme } from '@material-ui/core/styles';

//see https://github.com/mui-org/material-ui/tree/master/packages/material-ui/src/styles
export const MuiTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#2C4153',
    },
    secondary: {
      main: '#F1C419',
    },
  },
});