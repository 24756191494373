import React from 'react'
import ResourceLang from '../../resources/Language';
import PropTypes from 'prop-types';
import { Card, CardActionArea, CardActions, CardContent, IconButton, Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    cardRoot: {
        backgroundColor: '#f5f5f5'
    },
});

const GenericCard = ({element, hasActions, children, handleClick, ...props}) => {

    const classes = useStyles();

    const renderActions = () => {

        if (!hasActions)
            return;
        
        return(
            <CardActions style={{float:'right'}}>
                <Tooltip title={ResourceLang.edit} aria-label="edit">
                    <IconButton onClick={props.handleEdit(element)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={ResourceLang.delete} aria-label="delete">
                    <IconButton onClick={props.handleDelete(element)}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </CardActions>
        );
    }


    return (<>
        <Card className={classes.cardRoot}>
            <CardActionArea>
                <CardContent onClick={handleClick(element)}>
                    {children}
                </CardContent>
            </CardActionArea>
            {renderActions()}
        </Card>
        
    </>)
}

GenericCard.propTypes = {
    element: PropTypes.object,
    hasActions: PropTypes.bool.isRequired,
    handleClick: PropTypes.func.isRequired,
    handleEdit: PropTypes.func,
    handleDelete: PropTypes.func
};

export default GenericCard;