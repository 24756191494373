import React from 'react'
import PropTypes from 'prop-types'
import ResourceLang from '../../resources/Language'
import { getDateTimeStringFormat, is12Hours } from '../../utils/DateServices'
import {
  Grid,
  Popover,
  Typography,
  TextField,
  LinearProgress,
  FormControl,
  IconButton,
  InputAdornment,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Autocomplete } from '@material-ui/lab'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import EventIcon from '@material-ui/icons/Event'
//TODO: update @date-io/moment to lastest version after @material-ui/pickers 4 has been released.

const styles = (theme) => ({
  container: {
    padding: theme.spacing(2),
    maxWidth: 1000,
    minWidth: 400,
    marginBottom: 20,
  },
  formControl: {
    width: '100%',
    minWidth: 200,
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
})

const FilterPopover = ({
  filters,
  classes,
  anchorEl,
  updateAnchorEl,
  onChange,
}) => {
  const renderFilter = (filter, isLoading) => {
    switch (filter.type) {
      case 'select':
        return renderSelectFilter(filter, isLoading)
      default:
      case 'text':
        return renderTextFilter(filter, isLoading)
      case 'date':
        return renderDateFilter(filter, isLoading)
    }
  }

  const renderSelectFilter = (filter, isLoading) => {
    const { key, label, options, value = [], multiple } = filter

    return (
      <Autocomplete
        id={`filter_${key}`}
        className={classes.formControl}
        multiple={true}
        options={options || []}
        getOptionLabel={(option) => option.name}
        getOptionSelected={(option) => {
          const target = value.find((x) => x.value === option.value)
          return target != null ? true : false
        }}
        disabled={isLoading}
        value={value}
        onChange={(event, newValue) => {
          const result =
            !multiple && newValue.length === 2 ? [newValue[1]] : newValue
          handleValueChange(result, key)
        }}
        renderInput={(params) => (
          <TextField {...params} variant='standard' label={label} />
        )}
      />
    )
  }

  const renderTextFilter = (filter, isLoading) => {
    const { key, label, value, options } = filter

    return (
      <Autocomplete
        id={`filter_${key}`}
        className={classes.formControl}
        freeSolo
        options={options || []}
        getOptionLabel={(option) => {
          return option && typeof option === 'object' ? option.name : option
        }}
        disabled={isLoading}
        value={value == null ? '' : value}
        onInputChange={(event, newValue) =>
          handleValueChange(
            newValue && typeof newValue === 'object' ? newValue.name : newValue,
            key
          )
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant='standard'
            label={label}
            value={value || ''}
          />
        )}
      />
    )
  }

  const renderDateFilter = (filter, isLoading) => {
    const { key, label, value, maxValueKey, minValueKey } = filter
    const is12Hour = is12Hours(value || new Date())
    let maxDate = undefined
    let minDate = undefined

    if (maxValueKey) {
      const target = filters.find((x) => x.key === maxValueKey)
      maxDate = target && target.value ? target.value : undefined
    }

    if (minValueKey) {
      const target = filters.find((x) => x.key === minValueKey)
      minDate = target && target.value ? target.value : undefined
    }

    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <FormControl noValidate className={classes.formControl}>
          <DateTimePicker
            id={`filter_${key}`}
            ampm={is12Hour}
            showTodayButton
            label={label}
            disabled={isLoading}
            value={value ? value : null}
            onChange={(date) => handleValueChange(date.toDate(), key)}
            labelFunc={(date) => {
              return date ? getDateTimeStringFormat(date.toDate()) : ''
            }}
            minDate={minDate}
            maxDate={maxDate}
            strictCompareDates={true}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton>
                    <EventIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      </MuiPickersUtilsProvider>
    )
  }

  const handleValueChange = (newValue, key) => {
    const newFilters = [...filters]
    const target = newFilters.find((x) => x.key === key)

    if (target) {
      target.value = newValue
    }

    onChange(newFilters)
  }

  const renderContent = () => {
    let loadingSpinner = null
    let isLoading = false

    if (anchorEl) {
      //gather data if necessary
      const optionsToGet = filters.filter((x) => x.getOptions && !x.options)

      if (optionsToGet.length > 0) {
        const promisses = optionsToGet.map((x) => x.getOptions())
        Promise.all(promisses).then((responses) => {
          const newFilters = [...filters]

          for (let i = 0; i < optionsToGet.length; i++) {
            const target = newFilters.find((x) => x.key === optionsToGet[i].key)
            if (target) {
              target.options = responses[i] || []
            }
          }

          onChange(newFilters)
        })

        isLoading = true
        loadingSpinner = <LinearProgress />
      }
    }

    const xsSize = filters.length >= 2 ? 6 : 12
    const mdSize = filters.length >= 3 ? 6 : xsSize

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant={'h6'}>{ResourceLang.Filters}</Typography>
          {loadingSpinner}
        </Grid>
        {filters.map((item) => {
          return (
            <Grid item xs={xsSize} md={mdSize} key={item.key}>
              {renderFilter(item, isLoading)}
            </Grid>
          )
        })}
      </Grid>
    )
  }

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={() => {
        updateAnchorEl(null)
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <div className={classes.container}>{renderContent()}</div>
    </Popover>
  )
}

FilterPopover.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['date', 'text', 'select']),
      options: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
            .isRequired,
        })
      ),
      getOptions: PropTypes.func,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.instanceOf(Date),
        PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
              .isRequired,
          })
        ),
      ]),
      multiple: PropTypes.bool,
      maxValueKey: PropTypes.string,
      minValueKey: PropTypes.string,
    })
  ).isRequired,
  anchorEl: PropTypes.oneOfType([
    PropTypes.instanceOf(Element),
    PropTypes.func,
  ]),
  updateAnchorEl: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default withStyles(styles)(FilterPopover)
