
export function translateObjectToMenu(jsonObjectResponse) {
  var deviceOptionIdentifier = 1;
  jsonObjectResponse.forEach(function(subMenuItem){
    subMenuItem["name"]= subMenuItem.device_type_name
    subMenuItem["children"] = subMenuItem.hw_versions
    delete subMenuItem["device_type_name"]
    delete subMenuItem["hw_versions"]
    subMenuItem["id"] = deviceOptionIdentifier
    deviceOptionIdentifier++
    subMenuItem.children.forEach(function(version){
      version["name"]= version.version
      version["children"] = version.micro_controllers
      delete version["version"]
      delete version["micro_controllers"]
      version["id"] = deviceOptionIdentifier
      deviceOptionIdentifier++
      const MicroControllers = []
      version.children.forEach(function(micro){
        deviceOptionIdentifier++
        MicroControllers.push({ name: micro, id: deviceOptionIdentifier })
        version["children"] = MicroControllers
      })
    })
});
}

export function findElementById(menuItems, nodeId) {
  var elementFound = {}
  menuItems.forEach(function(subMenuItem){
      subMenuItem.children.forEach(function(version){
        version.children.forEach(function(micro){
          if (micro.id === nodeId) {
            elementFound.deviceType = subMenuItem.name
            elementFound.version = version.name
            elementFound.micro = micro.name
          }
        })
      })
  });

  return elementFound;
}