import React from 'react';
import { Route, Redirect } from "react-router";
import { connect } from 'react-redux';
import { setLoggedUser, SetUserData} from '../redux/actions/UserActions';
import SessionServices from '../utils/SessionServices';
import NavBar from '../components/navigation/NavBar';
import DashboardPage from '../pages/DashboardPage';
import EnvironmentPage from '../pages/EnvironmentPage';
import UploadFirmwarePage from '../pages/UploadFirmwarePage';
import UpdateFirmwarePage from '../pages/UpdateFirmwarePage';
import DevicePage from '../pages/devices/ListDevicePage';
import EditDevicePage from '../pages/devices/EditDevicePage';

const ProtectedRoutes = (props) => {
  const {isLoggedIn} = props;
  const {location} = props;

  const renderRoutes = () => {
    if (isLoggedIn) {
      return(<>
        <header>
          <NavBar />
        </header>
          <Route path="/dashboard" exact component={DashboardPage}/>
          <Route path="/dashboard/environment/:envId" exact component={EnvironmentPage}/>
          <Route path="/environment/:envId/uploadfirmware" exact component={UploadFirmwarePage} />
          <Route path="/environment/:envId/updatefirmware" exact component={UpdateFirmwarePage} />
          <Route path="/environment/:envId/device" exact component={DevicePage} />
          <Route path="/environment/:envId/device/:deviceId/summary" exact component={EditDevicePage} />
      </>);
    }
    else {

      if (location && location.pathname) {
        const isLoginRoute = (location.pathname.toLowerCase().indexOf('login') >= 0);

        if (!isLoginRoute)
          SessionServices.saveRedirectTo(location.pathname);
      }

      return <Redirect to="/" />;
    }
  };

  return(
      renderRoutes()
  )
}


const mapStateToProps = (state, ownProps) => {
    return {
        isLoggedIn: state.userStatus,
        userData: state.userData
    }
}

const mapDispatchToProps = (dispatch) => {
  return {
      setUserinStore: (user) => dispatch(setLoggedUser(user)),
      setUserData:(user) => dispatch(SetUserData(user)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoutes);
