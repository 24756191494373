import React from 'react';
import ResourceLang from '../../resources/Language';
import { Drawer, List, ListSubheader, ListItem, ListItemIcon, ListItemText,
    Typography, Divider } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import LogoEneida from '../../resources/img/logo_eneida_blue.svg';
import ApartmentIcon from '@material-ui/icons/Apartment';


//TODO: use side menu like this https://material-ui.com/store/previews/devias-kit/
const SideMenu = ({open, envID, toggleMenu, history}) => {
    const handleSelectMenuItem = (newRoute) => {
        history.push(newRoute);
    }

    const renderCurrentEnvironment = () => {
        if(envID !== undefined)
        {
            return(
                <ListItem button onClick={ (e) => handleSelectMenuItem('/dashboard/environment/'+envID) }>
                    <ListItemIcon><ApartmentIcon color="primary"/></ListItemIcon>
                    <ListItemText primary={ResourceLang.environment} />
                </ListItem>
            )
        }
        else
        {
            return (
                <></>
            )
        }
    }
    
    const renderSideMenuItems = () => {
        return (<div role="presentation"
            onClick={toggleMenu(false)}
            onKeyDown={toggleMenu(false)}
        >
            <List style={{width: 300}}
                subheader={
                    <ListSubheader component="div">
                        <ListItemText>
                            <img src={LogoEneida} alt="logoeneida" height="40px"/>
                            <Typography variant="h6">
                                <strong>{ResourceLang.eneida_backoffice}</strong>
                            </Typography>
                            <Typography variant="caption">{`v${process.env.REACT_APP_VERSION}`}</Typography>
                        </ListItemText>
                    </ListSubheader>
                }
            >
                <Divider />
                <ListItem button onClick={ (e) => handleSelectMenuItem('/dashboard') }>
                    <ListItemIcon><DashboardIcon color="primary"/></ListItemIcon>
                    <ListItemText primary={ResourceLang.dashboard} />
                </ListItem>
                
                {renderCurrentEnvironment()}
            </List>
        </div>);
    }


    return (<Drawer anchor={'left'}
        open={open}
        onClose={toggleMenu(false)}
    >
        {renderSideMenuItems()}
    </Drawer>);
}


export default SideMenu;