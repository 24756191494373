import React from 'react'
import PropTypes from 'prop-types'
import ResourceLang from '../../resources/Language'
import {
  AutoSizer,
  Column,
  Table,
  SortIndicator,
  defaultTableRowRenderer,
} from 'react-virtualized'
import { Link } from 'react-router-dom'
import {
  ContextMenu,
  ContextMenuTrigger,
  connectMenu,
  hideMenu,
} from 'react-contextmenu'
import { withStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import {
  TableRow,
  TableCell,
  Tooltip,
  Typography,
  IconButton,
  Paper,
  MenuList,
  MenuItem,
  TablePagination,
  Input,
} from '@material-ui/core'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const styles = (theme) => ({
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  table: {
    // temporary right-to-left patch, waiting for
    // https://github.com/bvaughn/react-virtualized/issues/454
    '& .ReactVirtualized__Table__headerRow': {
      flip: false,
      paddingRight: theme.direction === 'rtl' ? '0px !important' : undefined,
    },
  },
  tableRow: {
    cursor: 'pointer',
  },
  input: {
    width: '2rem',
  },
  caption: {
    fontSize: '0.875rem',
    fontFamily: 'Roboto',
  },
  tableRowHover: {
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  tableCell: {
    flex: 1,
  },
  tableFooter: {
    display: 'flex',
    alignItems: 'center',
  },
  footerlabel: {
    marginLeft: theme.spacing(3),
  },
  firstCell: {
    marginLeft: theme.spacing(1),
  },
  noClick: {
    cursor: 'initial',
  },
})

const MENU_TYPE = 'DYNAMIC'
const collectTriggerProps = (props) => props

const DynamicMenu = ({ id, contextMenuOptions, trigger }) => {
  const { data } = trigger || {}

  return (
    <ContextMenu id={id}>
      <Paper elevation={4}>
        <MenuList>
          {!trigger
            ? null
            : contextMenuOptions.map(({ label, onClick }, index) => {
                const onMenuItemClick = (e) => {
                  hideMenu()
                  onClick(e, data)
                }

                return (
                  <MenuItem key={index} onClick={onMenuItemClick}>
                    <Typography variant='inherit'>{label}</Typography>
                  </MenuItem>
                )
              })}
        </MenuList>
      </Paper>
    </ContextMenu>
  )
}

DynamicMenu.propTypes = {
  id: PropTypes.string.isRequired,
  contextMenuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
  trigger: PropTypes.shape({
    data: PropTypes.object.isRequired,
  }),
}

const ConnectedMenu = connectMenu(MENU_TYPE)(DynamicMenu)

class MuiPaginatedVirtualizedTable extends React.PureComponent {
  static defaultProps = {
    disableHeader: false,
    headerHeight: 48 * 2,
    rowHeight: 48,
    detailRowHeight: 48 * 2,
  }

  constructor(props) {
    super(props)

    this.state = {
      selectedIndex: -1,
    }
    this.tableRef = React.createRef()
    this._handleExpandClick = this._handleExpandClick.bind(this)
  }

  cellRenderer = ({ cellData, columnIndex, rowIndex, rowData }) => {
    const { columns, rowHeight, classes } = this.props
    const column = columns[columnIndex]
    let value = cellData

    if (column.dataKey === '#') {
      value = <div className={classes.firstCell}>{rowIndex + 1}</div>
    } else if (column.render) {
      value = column.render(rowData)
    } else if (column.date === true) {
      const date = new Date(cellData)
      value = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
    } else if (column.numeric === true) {
      const decimalPlaces = column.decimalPlaces || 2
      if (cellData && !isNaN(cellData)) {
        value = Number(
          Math.round(value + ('e' + decimalPlaces)) + ('e-' + decimalPlaces)
        ).toFixed(decimalPlaces)
      } else value = '-'
    }

    if (column.link) {
      value = (
        <Link
          id={`${column.dataKey}_click`}
          onClick={(event) => event.stopPropagation()}
          to={column.link(rowData)}
        >
          {value}
        </Link>
      )
    }

    return (
      <TableCell
        component='div'
        className={clsx([classes.tableCell, classes.flexContainer])}
        variant='body'
        style={{ height: rowHeight, justifyContent: column.align || undefined }}
        align={this.getColumnAlign(column)}
      >
        {value}
      </TableCell>
    )
  }

  headerRenderer = ({ label, columnIndex, dataKey, sortBy, sortDirection }) => {
    const { headerHeight, columns, classes } = this.props
    const column = columns[columnIndex]

    let content = ''
    if (columnIndex === 0) {
      content = (
        <div className={classes.firstCell}>
          <span>{label}</span>
          {sortBy === dataKey && (
            <SortIndicator sortDirection={sortDirection} />
          )}
        </div>
      )
    } else {
      content = (
        <>
          <span>{label}</span>
          {sortBy === dataKey && (
            <SortIndicator sortDirection={sortDirection} />
          )}
        </>
      )
    }

    return (
      <TableCell
        component='div'
        className={this.getHeaderRowClassName(column.disableSort)}
        variant='head'
        style={{
          fontWeight: 'bold',
          height: headerHeight,
          justifyContent: column.align || undefined,
        }}
        align={this.getColumnAlign(column)}
      >
        {content}
      </TableCell>
    )
  }

  rowRendererDetailed = (params) => {
    const { classes, rowHeight, detailRowHeight, detailRowRenderer } =
      this.props
    const { index, style, className, key } = params

    if (index === this.state.selectedIndex) {
      return (
        <div
          key={key}
          style={{ ...style, display: 'flex', flexDirection: 'column' }}
          className={className}
        >
          {defaultTableRowRenderer({
            ...params,
            style: { width: '100%', height: rowHeight },
          })}
          <TableRow
            style={{ width: '100%' }}
            component='div'
            className={classes.flexContainer}
            onClick={(event) => {
              let target = event.target

              while (target.getAttribute('role') !== 'row') {
                target = target.parentNode
              }

              target.previousElementSibling.click()
            }}
          >
            {detailRowRenderer(params, detailRowHeight)}
          </TableRow>
        </div>
      )
    } else return defaultTableRowRenderer(params)
  }

  renderDetailButton = (width) => {
    const { classes, headerHeight, rowHeight } = this.props
    const key = 'detailButton'
    const disableSort = true

    return (
      <Column
        key={key}
        disableSort={disableSort}
        dataKey={key}
        width={50 * width}
        className={classes.flexContainer}
        headerRenderer={() => {
          return (
            <TableCell
              component='div'
              className={this.getHeaderRowClassName(disableSort)}
              variant='head'
              style={{ height: headerHeight }}
              align={'left'}
            />
          )
        }}
        cellRenderer={({ cellData, columnIndex, rowIndex, rowData }) => {
          const isSelected = this.state.selectedIndex === rowIndex
          const expandIcon = isSelected ? (
            <ExpandLessIcon />
          ) : (
            <ExpandMoreIcon />
          )
          const label = isSelected
            ? ResourceLang.HideDetails
            : ResourceLang.ShowDetails

          return (
            <TableCell
              component='div'
              className={clsx([classes.tableCell, classes.flexContainer])}
              variant='body'
              style={{ height: rowHeight }}
              align={'left'}
            >
              <Tooltip title={label}>
                <IconButton
                  aria-label={key}
                  size='medium'
                  component='span'
                  onClick={(event) => {
                    event.stopPropagation()
                    this._handleExpandClick(rowIndex)
                  }}
                >
                  {expandIcon}
                </IconButton>
              </Tooltip>
            </TableCell>
          )
        }}
      />
    )
  }

  getRowClassName = ({ index }) => {
    const { classes } = this.props
    return index !== -1
      ? clsx(classes.tableRow, classes.flexContainer, classes.tableRowHover)
      : clsx(classes.tableRow, classes.flexContainer)
  }

  getHeaderRowClassName = (disableSort) => {
    const { classes } = this.props
    return clsx([
      classes.tableCell,
      classes.flexContainer,
      disableSort === true ? classes.noClick : classes.tableRow,
    ])
  }

  getRowHeight = ({ index }) => {
    return index === this.state.selectedIndex
      ? this.props.detailRowHeight + this.props.rowHeight
      : this.props.rowHeight
  }

  getColumnAlign = (column) => {
    if (column.align) return column.align

    return column.numeric ? 'right' : 'left'
  }

  _handleExpandClick(rowIndex) {
    const newIndex = this.state.selectedIndex === rowIndex ? -1 : rowIndex
    this.setState({ ...this.state, selectedIndex: newIndex })

    if (this.tableRef) {
      this.tableRef.recomputeRowHeights()
    }
  }

  render() {
    const {
      pagination,
      classes,
      columns,
      rowHeight,
      headerHeight,
      disableHeader,
      detailRowRenderer,
      contextMenuOptions,
      onGoToPage,
      pageError,
      labelPage,
      ...tableProps
    } = this.props
    const footerHeight = headerHeight

    const rowContentRenderer = detailRowRenderer
      ? this.rowRendererDetailed
      : defaultTableRowRenderer
    const rowRenderer = !contextMenuOptions
      ? rowContentRenderer
      : (params) => {
          const { style, key, rowData } = params

          return (
            <ContextMenuTrigger
              id={MENU_TYPE}
              key={key}
              style={style}
              holdToDisplay={1000}
              data={rowData}
              collect={collectTriggerProps}
            >
              {rowContentRenderer(params)}
            </ContextMenuTrigger>
          )
        }

    return (
      <>
        <AutoSizer>
          {({ height, width }) => (
            <>
              <Table
                height={height - footerHeight}
                width={width}
                rowHeight={detailRowRenderer ? this.getRowHeight : rowHeight}
                gridStyle={{ direction: 'inherit' }}
                disableHeader={disableHeader}
                headerHeight={headerHeight}
                className={classes.table}
                {...tableProps}
                rowRenderer={rowRenderer}
                rowClassName={this.getRowClassName}
              >
                {columns.map(({ dataKey, columnWidth, ...other }, index) => {
                  const cellWidth = columnWidth * width
                  return (
                    <Column
                      key={dataKey}
                      headerRenderer={(headerProps) =>
                        this.headerRenderer({
                          ...headerProps,
                          columnIndex: index,
                        })
                      }
                      className={classes.flexContainer}
                      cellRenderer={this.cellRenderer}
                      dataKey={dataKey}
                      width={cellWidth}
                      {...other}
                    />
                  )
                })}
                {detailRowRenderer ? this.renderDetailButton(width) : null}
              </Table>
              <div
                className={classes.tableFooter}
                style={{
                  width: width,
                  height: footerHeight,
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <div
                  className={classes.caption}
                  style={{ marginRight: '1rem' }}
                >
                  Page:
                </div>
                <Input
                  classes={{ root: classes.input, input: classes.caption }}
                  value={labelPage}
                  onChange={onGoToPage}
                  error={pageError}
                />
                <TablePagination
                  component='div'
                  {...pagination}
                  classes={{ caption: classes.caption }}
                />
              </div>
            </>
          )}
        </AutoSizer>
        {contextMenuOptions ? (
          <ConnectedMenu contextMenuOptions={contextMenuOptions} />
        ) : null}
      </>
    )
  }
}

MuiPaginatedVirtualizedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataKey: PropTypes.string.isRequired,
      label: PropTypes.string,
      numeric: PropTypes.bool,
      date: PropTypes.bool,
      align: PropTypes.oneOf(['left', 'center', 'right']),
      columnWidth: PropTypes.number.isRequired,
      render: PropTypes.func,
      link: PropTypes.func,
    })
  ).isRequired,
  pagination: PropTypes.object,
  disableHeader: PropTypes.bool,
  headerHeight: PropTypes.number,
  onRowClick: PropTypes.func,
  rowHeight: PropTypes.number,
  detailRowRenderer: PropTypes.func,
  detailRowHeight: PropTypes.number,
  contextMenuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
  onGoToPage: PropTypes.func,
  pageError: PropTypes.bool,
  labelPage: PropTypes.string,
}

export default withStyles(styles)(MuiPaginatedVirtualizedTable)
