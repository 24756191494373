import React , {useState, useEffect} from 'react'
import { withRouter } from "react-router";
import PageHeader from '../components/headers/PageHeader';
import {Button, Typography, Divider, Chip } from '@material-ui/core'
import DeviceMenuItems from '../components/MenuItems/MenuItems'
import { makeStyles } from '@material-ui/core/styles';
import ResourceLang from '../resources/Language';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {DropzoneArea} from 'material-ui-dropzone'
import {FirmwareAPI} from '../utils/APIRequester';
import MessageSnackbar from '../components/notifications/MessageSnackbar';
import ExtensionIcon from '@material-ui/icons/Extension';
import MemoryIcon from '@material-ui/icons/Memory';
import DevicesOtherIcon from '@material-ui/icons/DevicesOther';
import PageLoading from '../components/loadings/PageLoading';
import Grid from '@material-ui/core/Grid';
import {translateObjectToMenu, findElementById} from '../utils/FirmwareServices';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '100vh',
        marginTop: 1
    },
    body_info:{
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(2)
    },
    body_dropzone:{
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2)
    },
    treeView: {
        backgroundColor: '#2C4153',
        overflow: 'auto'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(2),
    },
    chip: {
        marginRight: 5
    }
}));


const UploadFirmwarePage = ({match, location, history, ...props}) => {
    const classes = useStyles();
    const [ DropZoneAreakey, setDropZoneAreaKey] = useState(0);
    const [ fileToUpload, setFile ] = useState(undefined);
    const [ MenuItems, setMenuItems ] = useState(undefined);
    const [ snackbar, setSnackbar] = useState({ open: false, variant: 'error', message: ''});
    const [ micro_controller, setMicro ] = useState(undefined);
    const [ device_type_name, setDevice ] = useState(undefined);
    const [ device_version, setVersion ] = useState(undefined);
    
    const envId = match.params.envId;

    useEffect(() => {
        if (!MenuItems)
        {
            FirmwareAPI.getStructure(envId).then(response => {
                translateObjectToMenu(response)
                setMenuItems(response);
            });
        }
    }, [envId, MenuItems]);

    
    const renderDeviceTypeIcon = () => {
        if (device_type_name) {
            return (<Chip color="primary" className={classes.chip}
                icon={<DevicesOtherIcon />}
                label={device_type_name} />);
        }
    
        return null;
    }

    const renderExtensionIcon = () => {
        if(device_version) {
            return (<Chip color="primary" className={classes.chip}
                icon={<ExtensionIcon />}
                label={device_version} />);
        }
            
        return null;
    }

    const renderMemoryIcon = () => {
        if(micro_controller) {
            return (<Chip color="primary" className={classes.chip}
                icon={<MemoryIcon />}
                label={micro_controller} />);
        }
        
        return null;
    }
    

    const handleChange = (fileToUpload) => {
        if(Object.keys(fileToUpload).length > 0) {
            setFile(fileToUpload);
        }
        else {
            setFile(undefined);
        } 
    }

    const renderUploadButton= () => {
        if (!micro_controller || !device_type_name || !device_version) {
            return null;
        }
        
        return(<div style={{ float: 'right' }}>
            <Button disabled={!fileToUpload}
                variant="contained"
                color="default"
                startIcon={<CloudUploadIcon />}
                onClick={uploadToServer}
            >
                {ResourceLang.upload}
            </Button>
        </div>);
    }

    const renderDropZoneArea = () => {
        if (!micro_controller || !device_type_name || !device_version ) {
            return null;
        }
      
        return(<DropzoneArea
            key={DropZoneAreakey}
            filesLimit={1}
            dropzoneText={ResourceLang.upload_dropzone_area_firmware_message}
            showFileNames={true}
            maxFileSize={4194304}
            onChange={handleChange.bind(this)}/>);
    }

    const uploadToServer = () => {
        const formData = new FormData();    
        formData.append(fileToUpload[0].name, new Blob( fileToUpload)); 
      
        if(fileToUpload && fileToUpload[0]!==undefined) {
            formData.append('name', fileToUpload[0].name); 
        }

        FirmwareAPI.uploadFileToServer(
            envId,
            device_type_name,
            device_version.substring(1),
            micro_controller,formData
        )
        .then(response => {
            setSnackbar((prevState) => ({
                ...prevState,
                open: true,
                message: ResourceLang.upload_success_message,
                variant: 'success'
            }));
            setDropZoneAreaKey(DropZoneAreakey +1)
            setFile(undefined)
        })
        .catch((status, message, data) => {
            if(status.data.Error === "invalid_file_size") {
                message = ResourceLang.upload_error_file_size_message;
            }
            else if(status.data.Error === "invalid_file") {
                message = ResourceLang.upload_error_no_file;
            }
            else {
                message = ResourceLang.generic_error;
            }
          
            setSnackbar((prevState) => ({
                ...prevState,
                open: true,
                message: message,
                variant: 'error'
            }));
        });
    }

    const setDeviceChoosen = (ItemSelected) => {
        if(ItemSelected) {
            const deviceSelected = findElementById(MenuItems, ItemSelected)
            if(deviceSelected) {
                setDevice(deviceSelected.deviceType)
                setVersion(deviceSelected.version)
                setMicro(deviceSelected.micro)
            }
        }
    }

    const renderHeader = () => {
        return (<PageHeader
            title={ResourceLang.upload_firmware}
            icon={<MemoryIcon color="primary" fontSize="large"/>}>
        </PageHeader>)
    }

    const handleSnackbarClose = () => {
        setSnackbar((prevState) => ({...prevState, open: false}));
    }

    const renderSnackbar = () => {
        return (<MessageSnackbar open={snackbar.open}
            message={snackbar.message}
            onClose={handleSnackbarClose}
            variant={snackbar.variant}/>);
    }

    return (<div className={classes.root}>
        <Grid item xs={2} className={classes.treeView} >
            <DeviceMenuItems props={props} menuItems={MenuItems} ItemSelected={setDeviceChoosen}/>
            <Divider/>
        </Grid>
        <Grid item className={classes.content}>
            {renderHeader()}
            <br/>
            <div className={classes.body_info}>
                <Typography paragraph>{ResourceLang.micro_controller_info}</Typography>
                <b>{ResourceLang.micro_controller} </b>&nbsp;
                {renderDeviceTypeIcon()}
                {renderExtensionIcon()}
                {renderMemoryIcon()}
                <div className={classes.body_dropzone}>
                    {renderDropZoneArea()}
                </div>
                {renderUploadButton()}
                {renderSnackbar()}
            </div>
        </Grid>
        {!MenuItems ? <PageLoading /> : null}
    </div>);
}


export default withRouter(UploadFirmwarePage);