import React from 'react';
import ResourceLang from '../../../resources/Language';
import { withRouter } from "react-router";
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import MuiVirtualizedTable from '../../tables/MuiVirtualizedTable';
import PropTypes from 'prop-types';
import { DEVICE_KEYS_ENUM, SETTINGS_TYPE_ENUM }  from '../../../utils/Enums';
import NTPSettings from './NTPSettings';
import APNSetting from './APNSettings';
import { Autocomplete } from '@material-ui/lab';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import {Typography, TextField} from '@material-ui/core';
import Button from '@material-ui/core/Button';

const SelectDeviceSettings = ({ devices, selectedDevices, settingsToUpdate,
    settingsType , handleSelection, selectAllDevices, clearAllSelectedDevices  }) => {

  const [serialNumberFilter, setSerialNumberFilter] = React.useState();

  const handleSelectDevice = (rowData, event) => {
    handleSelection(rowData, event.target.checked)
  }

  const isSelected = (rowData) => {
    const isChecked = selectedDevices.some(function (device) {
      return device.device_id === rowData.device_id;
    });

    return isChecked
  }


  const renderDevicesTable = () => {
    if (devices !== undefined && devices.length > 0) {
      const columns = [
        {
          columnWidth: 35,
          label: '#',
          dataKey: DEVICE_KEYS_ENUM.DEVICE_ID,
          disableSort: true,
          render: (rowData) => {
            return (<Checkbox id={`Checkbox${rowData.device_id}`}
              onClick={(e) => { handleSelectDevice(rowData, e) }}
              color='primary'
              checked={isSelected(rowData)} />)
          }
        },
        {
          columnWidth: 75,
          label: ResourceLang.serial_number,
          dataKey: DEVICE_KEYS_ENUM.SERIAL_NUMBER,
          disableSort: false,
        }
      ];
      if(!serialNumberFilter){
        return (<MuiVirtualizedTable
          totalRows={devices.length}
          rowCount={devices.length}
          rowGetter={({ index }) => devices[index]}
          sortBy={DEVICE_KEYS_ENUM.SERIAL_NUMBER}
          sortDirection={'ASC'}
          onRowClick={() => { }}
          onLoadMore={() => { }}
          columns={columns}
        />);
      }
      else{
        return (<MuiVirtualizedTable
          totalRows={devices.filter(obj => obj.serial_number === serialNumberFilter).length}
          rowCount={devices.filter(obj => obj.serial_number === serialNumberFilter).length}
          rowGetter={({ index }) => devices[index]}
          sortBy={DEVICE_KEYS_ENUM.SERIAL_NUMBER}
          sortDirection={'ASC'}
          onRowClick={() => { }}
          onLoadMore={() => { }}
          columns={columns}
        />);
      }
      
    }
    else {
      return (<></>)
    }

  }

  const renderSettingsTable = () => {
    if(settingsType === SETTINGS_TYPE_ENUM.APN) {
        return(
            <APNSetting settings = {settingsToUpdate}/>
        )
    }
    else if (settingsType === SETTINGS_TYPE_ENUM.NTP) {
        return(
            <NTPSettings settings = {settingsToUpdate}/>
        )
    }
    else{
        return (<></>)
    }
  }

  const renderSelectButton = () => {
    if (!selectedDevices.length) {
        return (<Button size="small" style={{float: 'right'}}
        onClick={() => {selectAllDevices()}}>
                {" " + ResourceLang.all} <CheckIcon />
        </Button>);
    }
    else {
        return (<Button size="small" style={{float: 'right'}}
        onClick={() => {clearAllSelectedDevices()}}>
                {" " + ResourceLang.clean} <ClearIcon />
        </Button>);
    }
}


  const renderDeviceSearch = () => {
      if(selectedDevices !== undefined){
            return (<Autocomplete id={`filter_device`}
            freeSolo
            options={devices.map( (obj) => obj.serial_number)}
            onInputChange={(event, newValue, reason) => {
              console.log(newValue)
                if (reason === 'clear'){
                  setSerialNumberFilter(newValue);
                }
                else{
                  setSerialNumberFilter(newValue);
                }
            }}
            renderInput={(params) => (
            <TextField {...params}
                variant="standard"
                label={ResourceLang.serial_number}/>
            )}
        />);
      }
      else{
          return (<></>)
      }
}


  const renderHeader = () => {
    return (<Grid container spacing={2}>
        <Grid item lg={8} md={6} xs={12} style={{alignSelf: 'flex-end'}}>
            <Typography component="div" variant="subtitle1">
                {ResourceLang.total_devices} ({selectedDevices ? selectedDevices.length : 0})
            </Typography>
        </Grid>
        <Grid item lg={4} md={6} xs={12}>
            <Grid container spacing={2}
                direction="row" justify="flex-end" alignItems="flex-end">
                <Grid item sm={8} xs={9}>{renderDeviceSearch()}</Grid>
                <Grid item sm={4} xs={3}>{renderSelectButton()}</Grid>
            </Grid>
        </Grid>
    </Grid>);
}

  return (
    <Grid container spacing={2}>
        <Grid item xs={12}>
            {renderHeader()}
        </Grid>
      <Grid item lg={4} xs={12} style={{ height: 450 }}>
        {renderDevicesTable()}
      </Grid>
      <Grid item lg={8} xs={12} style={{ maxHeight: 450 }}>
        {renderSettingsTable()}
      </Grid>
    </Grid>
  );
}

SelectDeviceSettings.propTypes = {
  devices: PropTypes.array.isRequired,
  selectedDevices: PropTypes.array.isRequired,
  settingsToUpdate: PropTypes.array.isRequired,
  settingsType: PropTypes.number.isRequired,
  handleSelection: PropTypes.func.isRequired,
  selectAllDevices: PropTypes.func.isRequired,
  clearAllSelectedDevices: PropTypes.func.isRequired
};

export default withRouter(SelectDeviceSettings);