import Fab from '@material-ui/core/Fab';
import { withStyles } from '@material-ui/core/styles';

export const CircularButtonTransparent = withStyles(theme => ({
    root: {
      color: '#233442',
      backgroundColor: 'white',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
    },
}))(Fab);


