import React, { useEffect, useState } from 'react'
import ResourceLang from '../../resources/Language'
import { withRouter } from 'react-router'
import Grid from '@material-ui/core/Grid'
import Checkbox from '@material-ui/core/Checkbox'
import { compareValues } from '../../utils/BrowserServices'
import MuiFiltersSelectAllVirtualizedTable from '../tables/MuiFiltersSelectAllVirtualizedTable'
import MuiVirtualizedTable from '../tables/MuiVirtualizedTable'
import CircularLoading from '../loadings/CircularLoading'
import { SortDirection } from 'react-virtualized'
import PropTypes from 'prop-types'
import { DEVICE_KEYS_ENUM } from '../../utils/Enums'
import TableHeader from '../tables/TableHeader'

const SelectDevices = ({
  devices,
  selectedDevices,
  availableVersions,
  filteredAvailableVersions,
  handleSelection,
  handleAllSelections,
  isLoading,
  currentVersions,
  updateVersions,
  handleFilters,
}) => {
  const pageHeight = window.innerHeight
  const percentageOffSet = 0.45
  const [versionsSort, setVersionsSort] = useState({
    sortBy: DEVICE_KEYS_ENUM.CURRENT_VERSION,
    sortDirection: 'DESC',
  })
  const [devicesSort, setDevicesSort] = useState({
    sortBy: DEVICE_KEYS_ENUM.SERIAL_NUMBER,
    sortDirection: 'DESC',
  })
  const [allSelected, setAllSelected] = useState(false)

  const [anchorEl, updateAnchorEl] = useState(null)
  const [filters, setFilters] = useState([
    {
      key: 'current_version',
      label: ResourceLang.current_version,
      type: 'select',
      options: currentVersions,
    },
    {
      key: 'update_version',
      label: ResourceLang.update_version,
      type: 'select',
      options: updateVersions,
    },
  ])

  useEffect(() => {
    filters.forEach((filter) => {
      if (filter.key === 'current_version') {
        filter.options = currentVersions
      }

      if (filter.key === 'update_version') {
        filter.options = updateVersions
      }
    })
  }, [currentVersions, updateVersions, filters])

  useEffect(() => {
    handleFilters(filters)
  }, [filters, handleFilters])

  const handleSelectDevice = (rowData, event) => {
    handleSelection(rowData, event.target.checked)
  }

  const isSelected = (rowData) => {
    const isChecked = selectedDevices.some(function (device) {
      return device.device_id === rowData.device_id
    })

    return isChecked
  }

  const getTableItems = (items, sort) => {
    if (sort.sortDirection === SortDirection.ASC) {
      items.sort((a, b) => compareValues(b[sort.sortBy], a[sort.sortBy]))
    } else {
      items.sort((a, b) => compareValues(a[sort.sortBy], b[sort.sortBy]))
    }
    return items
  }

  const selectAll = (event) => {
    handleAllSelections(devices, event.target.checked)
    setAllSelected(event.target.checked)
  }

  const renderDevicesTable = () => {
    if (devices !== undefined && devices.length > 0) {
      const columns = [
        {
          columnWidth: 35,
          label: '#',
          dataKey: DEVICE_KEYS_ENUM.DEVICE_ID,
          disableSort: true,
          render: (rowData) => {
            return (
              <Checkbox
                id={`Checkbox${rowData.device_id}`}
                onClick={(e) => {
                  handleSelectDevice(rowData, e)
                }}
                color='primary'
                checked={isSelected(rowData)}
              />
            )
          },
        },
        {
          columnWidth: 75,
          label: ResourceLang.serial_number,
          dataKey: DEVICE_KEYS_ENUM.SERIAL_NUMBER,
          disableSort: false,
        },
        {
          columnWidth: 60,
          label: ResourceLang.current_version,
          dataKey: DEVICE_KEYS_ENUM.CURRENT_VERSION,
          disableSort: false,
        },
        {
          columnWidth: 60,
          label: ResourceLang.update_version,
          dataKey: DEVICE_KEYS_ENUM.UPDATE_VERSION,
          disableSort: false,
        },
      ]

      const handleSort = (event) => {
        setDevicesSort({
          sortBy: event.sortBy,
          sortDirection: event.sortDirection,
        })
      }

      const tableItems = getTableItems(devices, devicesSort)

      return (
        <>
          <TableHeader
            tableTitle={ResourceLang.device_firmware_table}
            filters={filters}
            anchorEl={anchorEl}
            updateAnchorEl={updateAnchorEl}
            setFilters={setFilters}
          />
          <MuiFiltersSelectAllVirtualizedTable
            totalRows={tableItems.length}
            rowCount={tableItems.length}
            rowGetter={({ index }) => tableItems[index]}
            sort={handleSort}
            sortBy={devicesSort.sortBy}
            sortDirection={devicesSort.sortDirection}
            onRowClick={() => {}}
            onLoadMore={() => {}}
            columns={columns}
            selectAll={selectAll}
            allSelected={allSelected}
          />
        </>
      )
    } else {
      return <></>
    }
  }

  const handleAvailableVersions = (index) => {
    return filteredAvailableVersions[index]
  }

  const renderVersionsTable = () => {
    if (!availableVersions !== undefined && availableVersions.length > 0) {
      const columns = [
        {
          columnWidth: 50,
          label: ResourceLang.available_versions,
          dataKey: DEVICE_KEYS_ENUM.CURRENT_VERSION,
          disableSort: false,
        },
        {
          columnWidth: 75,
          label: ResourceLang.creation_date,
          dataKey: DEVICE_KEYS_ENUM.UPDATE_REQUEST_DATE_VERSION,
          disableSort: false,
        },
      ]

      const handleSort = (event) => {
        setVersionsSort({
          sortBy: event.sortBy,
          sortDirection: event.sortDirection,
        })
      }

      const tableItems = getTableItems(filteredAvailableVersions, versionsSort)

      return (
        <>
          <TableHeader
            tableTitle={ResourceLang.available_versions_table}
            filters={filters}
            anchorEl={anchorEl}
            updateAnchorEl={updateAnchorEl}
            setFilters={setFilters}
            showFilters={false}
          />
          <MuiVirtualizedTable
            totalRows={tableItems.length}
            rowCount={tableItems.length}
            rowGetter={({ index }) => handleAvailableVersions(index)}
            sort={handleSort}
            sortBy={versionsSort.sortBy}
            sortDirection={versionsSort.sortDirection}
            columns={columns}
          />
        </>
      )
    } else {
      return <></>
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid
        item
        lg={7}
        xs={12}
        style={{ height: pageHeight * percentageOffSet }}
      >
        {isLoading === false ? renderDevicesTable() : <CircularLoading />}
      </Grid>
      <Grid
        item
        lg={5}
        xs={12}
        style={{ height: pageHeight * percentageOffSet }}
      >
        {isLoading === false ? renderVersionsTable() : <CircularLoading />}
      </Grid>
    </Grid>
  )
}

SelectDevices.propTypes = {
  devices: PropTypes.array.isRequired,
  selectedDevices: PropTypes.array.isRequired,
  availableVersions: PropTypes.array.isRequired,
  filteredAvailableVersions: PropTypes.array.isRequired,
  handleSelection: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  currentVersions: PropTypes.array,
  updateVersions: PropTypes.array,
  handleFilters: PropTypes.func,
}

export default withRouter(SelectDevices)
