export function getLocalDateTimeString(value){
    if(!value || value === '')
        return '';

    const date = (typeof(value) === 'string' || typeof(value) === 'number' ?
        new Date(value) : value);

    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
}

export function getDateTimeStringFormat(date){
    if(date === null || date === undefined || date === '')
        return '';
            
    return date.toLocaleDateString() + " " + date.toLocaleTimeString();
}

export function is12Hours(date) {
    return date.toLocaleTimeString().match(/am|pm/i);
}

export function addDays (date, days) {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + days);
    return newDate;
}

export function addMonths (date, moths) {
    const newDate = new Date(date);
    newDate.setMonth(date.getMonth() + moths);
    return newDate;
}


