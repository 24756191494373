import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import ResourceLang from '../../../resources/Language';
import PropTypes from 'prop-types';
import DialogStepper from '../../stepper/DialogStepper'
import Grid from '@material-ui/core/Grid';
import Dialog from '../Dialog';
import SelectDeviceSettings from "../../panels/settings/SelectDeviceSettings";
import UpdateNTPSettingsReview from '../../panels/settings/UpdateNTPSettingsReview';
import UpdateAPNSettingsReview from '../../panels/settings/UpdateAPNSettingsReview';
import MessageSnackbar from '../../notifications/MessageSnackbar';
import { FirmwareAPI } from "../../../utils/APIRequester";
import {SETTINGS_TYPE_ENUM }  from '../../../utils/Enums';


function getSteps() {
  return [ResourceLang.select_devices,
          ResourceLang.review_and_confirmation];
}

const SELECT_DEVICES_STEP = 0;
const REVIEW_STEP = 1;

const DeviceSettingsDialog = ({ isOpen, closeModal, settingsType, envId,
    deviceItemId, dataToBeUpdated }) => {
  const [ snackbar, setSnackbar] = React.useState({ open: false, variant: 'error', message: ''});
  const [ state, setState] = React.useState({
    devices: [],
    selectedDevices: [],
    activeStep: 0,
    name: undefined,
    description: undefined,
    disableNext: true,
    error: false,
    isLoading: true,
    selectedSds: []
  });
  const steps = getSteps();

  const getAllData = React.useCallback(() => {
    Promise.all([
        FirmwareAPI.getDevicesFamilyByDeviceId(envId, deviceItemId)
    ])
      .then(responses => {
        if (responses[0] && Array.isArray(responses[0])) {
            setState(prevState => ({
                ...prevState,
                isLoading: false,
                devices: responses[0]
              }));
        }
      }).catch(error => {
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      })
    },[envId, deviceItemId]);


  useEffect(() => {
    const load = async () => {
      getAllData()
    }
    if(isOpen){
        load();
        setState(prevState => ({
            ...prevState,
            isLoading: false
        }));
    }

  }, [isOpen, getAllData]);


  const handleClose = () => {
    setState((prevState) => ({
        ...prevState, 
        activeStep: SELECT_DEVICES_STEP,
        devices: [],
        selectedDevices: [],
        disableNext: true
    }));
    closeModal()
  };

    function updateAPNSettingsIntoServer(deviceIds, dataToUpdate){
        const formData = new FormData();
        formData.append('gprs', JSON.stringify(dataToUpdate));
        formData.append('ids', JSON.stringify(deviceIds));
        FirmwareAPI.putGPRSSettingsInBatchByDevice(envId, formData)
        .then(response => {
            setSnackbar((prevState) => ({...prevState, 
                open: true,
                variant: "success",
                message: ResourceLang.update_devices_with_success
            }));
            }).catch(error => {
            setSnackbar((prevState) => ({...prevState, 
                open: true,
                variant: "error",
                message: ResourceLang.generic_error
            }));
        })
    }

    function updateNTPSettingsIntoServer(deviceIds,dataToUpdate ){
        const formData = new FormData();
        formData.append('ntp', JSON.stringify(dataToUpdate));
        formData.append('ids', JSON.stringify(deviceIds));
        FirmwareAPI.putNTPSettingsInBatchByDevice(envId, formData)
        .then(response => {
            setSnackbar((prevState) => ({...prevState, 
                open: true,
                variant: "success",
                message: ResourceLang.update_devices_with_success
            }));
        }).catch(error => {
            setSnackbar((prevState) => ({...prevState, 
                open: true,
                variant: "error",
                message: ResourceLang.generic_error
            }));
        })
    }

  function prepareItemsToUpdateOnServer(devices, dataToUpdate) {
    var deviceIds = []
    if(devices && dataToUpdate){
        devices.forEach(item => {
            deviceIds.push(item.device_id)
        });
        if(settingsType === SETTINGS_TYPE_ENUM.APN){
            updateAPNSettingsIntoServer(deviceIds, dataToUpdate)
        }
        else if (settingsType === SETTINGS_TYPE_ENUM.NTP){
            updateNTPSettingsIntoServer(deviceIds, dataToUpdate)
        }
    }
  }

  const submitUpdate = () => {
    prepareItemsToUpdateOnServer(state.selectedDevices, dataToBeUpdated[0])
    setState((prevState) => ({
        ...prevState, 
        activeStep: SELECT_DEVICES_STEP,
        devices: [],
        selectedDevices: [],
        disableNext: true
    }));
    closeModal();
  }

  const handleNext = () => {
    if(state.activeStep === REVIEW_STEP){
        submitUpdate()
    }
    else{
        setState((prevState) => ({
            ...prevState, 
            activeStep: state.activeStep + 1
        }));
    }
  };

    const handleBack = () => {
        if(state.activeStep === SELECT_DEVICES_STEP){
            setState((prevState) => ({
                ...prevState, 
                activeStep: SELECT_DEVICES_STEP,
                devices: [],
                selectedDevices: [],
                disableNext: true
            }));
            closeModal();
        }
        else{
            setState((prevState) => ({
                ...prevState, 
                activeStep: state.activeStep - 1
            }));
        }
    };

    const setDeviceSelected = (rowData, isChecked) => {
        var devices = state.selectedDevices;

        if (isChecked === true) {
        devices.push(rowData);
        } else {
        devices = state.selectedDevices.filter(function (device) {
            return device.device_id !== rowData.device_id;
        });
        }

        return devices;
    }


    const handleSelection = (rowData, isChecked) => {
        if (rowData !== undefined) {
            const devices = setDeviceSelected(rowData, isChecked)
            if(devices.length){
                setState((prevState) => ({
                    ...prevState, 
                    selectedDevices: devices,
                    disableNext: false
                }));
            }
            else{
                setState((prevState) => ({
                    ...prevState, 
                    selectedDevices: devices,
                    disableNext: true
                }));
            }
        }
    }

    const selectAllDevices = () =>{
        setState((prevState) => ({
            ...prevState, 
            selectedDevices: state.devices,
            disableNext: false
        }));
    }

    const clearAllSelectedDevices = () =>{
        setState((prevState) => ({
            ...prevState, 
            selectedDevices: [],
            disableNext:true
        }));
    }

  const renderBody = () => {
    if(state.devices !== undefined)
    {
        switch (state.activeStep) {
            case 0:
                return(<SelectDeviceSettings devices={state.devices}
                    selectedDevices={state.selectedDevices}
                    settingsToUpdate={dataToBeUpdated}
                    settingsType={settingsType}
                    handleSelection={handleSelection}
                    selectAllDevices={selectAllDevices}
                    clearAllSelectedDevices={clearAllSelectedDevices} />);
            case 1:
                if(settingsType === SETTINGS_TYPE_ENUM.APN){
                    return(<UpdateAPNSettingsReview devices={state.selectedDevices}
                        dataToBeUpdated={dataToBeUpdated}/>
                    )
                }
                else if(settingsType === SETTINGS_TYPE_ENUM.NTP){
                    return(<UpdateNTPSettingsReview devices={state.selectedDevices}
                        dataToBeUpdated={dataToBeUpdated}/>
                    )
                }
                break;
                
            default:
                return (<></>);
            }
    }
  }

  const renderTitle = () => {
      if(settingsType === SETTINGS_TYPE_ENUM.APN){
        return (
            <>{ResourceLang.device_settings_update} -
             <strong> {ResourceLang.Apn_Settings}</strong></>
        )
      }
      else if (settingsType === SETTINGS_TYPE_ENUM.NTP){
        return (
            <>{ResourceLang.device_settings_update} - 
             <strong> {ResourceLang.ntp_settings}</strong></>
        )
      }
      else{
        return (
            <>{ResourceLang.device_settings_update} -
             <strong> {ResourceLang.unknowm}</strong></>
        )
      }
  }

  const renderContent = () => {
    return (
      <div style={{ minHeight: 300 }}>
        <DialogStepper activeStep={state.activeStep} steps={steps} />
        {renderBody()}
      </div>
    )
  }

  const renderFooter = () => {
    return (
      <div>
        <div>
          <div style={{ float: 'right' }}>
            <Button disabled={state.disableBack} onClick={handleBack} style={{ marginRight: '10px' }}>
              <>{ResourceLang.back}</>
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              style={{ marginRight: '10px' }}
              disabled={state.disableNext}>
              {state.activeStep === steps.length - 1 ? 'submit' : 'next'}
            </Button>
          </div>
        </div>
      </div>
    )
  }

    const handleSnackbarClose = () =>{
        setSnackbar((prevState) => ({...prevState, 
            open: false, 
        }));
    }

    const renderSnackbar = () => {
        return (<MessageSnackbar open={snackbar.open}
            message={snackbar.message}
            onClose={handleSnackbarClose}
            variant={snackbar.variant}
        />);
    }


  return (
    <div >
      <Dialog onClose={handleClose}
        open={isOpen}
        fullWidth={true}
        maxWidth={'md'}
        titleRenderer={renderTitle}>
        <Grid item xs={12}>
            {renderContent()}
            {renderFooter()}
        </Grid>
      </Dialog>
      {renderSnackbar()}
    </div>
  );
}

DeviceSettingsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  settingsType: PropTypes.number.isRequired,
  envId: PropTypes.string.isRequired,
  deviceItemId: PropTypes.string.isRequired,
  dataToBeUpdated: PropTypes.array.isRequired
};

export default DeviceSettingsDialog;