import React from 'react'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Toolbar, Typography } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    leftIcon: {
        margin: theme.spacing(1),
        float: 'left'
    },
    toolbar: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
        flexDirection: 'row',
    },
    divider: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    title: {
        flex: 1,
    }
}));


const PageHeader = ({title, icon, ...props}) => {
    const classes = useStyles();

    return (<>
        <Toolbar className={classes.toolbar}>
            <div className={classes.leftIcon}>
                {icon}
            </div>
            <Typography variant="h4" className={classes.title}>
                {title}
            </Typography>
            {props.children}
        </Toolbar>
    </>);
}

PageHeader.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired
  };

export default PageHeader;