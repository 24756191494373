import React from 'react';
import ResourceLang from '../../../resources/Language';
import PropTypes from 'prop-types';
import { Grid, InputLabel } from '@material-ui/core';
import RawVirtualizedGrid from '../../tables/RawVirtualizedGrid';
import cloneDeep from 'lodash/cloneDeep';

const UpdateNTPSettings = ({devices, dataToBeUpdated}) => {

    const DeviceKeysEnum = {
        SERIAL_NUMBER: 'serial_number'
    }

    const DEVICE_SETTINGS_KEYS = {
        TIMESERVER_URL: 'timeserver_url',
        TIMESERVER_PORT:'timeserver_port',
        TIMESERVER_PROTOCOL: 'timeserver_protocol',
        TIMESERVER_TYPE: 'timeserver_type' 
    }

    const NTP_SETTINGS_INDEX = {
        NTP: 0,
        HTP: 1,
        DEEPGRID: 2
    }

    function translateSettingsToTableRender(settingsToUpdate) {
        if(settingsToUpdate.timeserver_type !== undefined){
          if(settingsToUpdate.timeserver_type === NTP_SETTINGS_INDEX.NTP){
            settingsToUpdate.timeserver_type = ResourceLang.ntp
          }
          else if (settingsToUpdate.timeserver_type === NTP_SETTINGS_INDEX.HTP)
          {
            settingsToUpdate.timeserver_type = ResourceLang.htp
          }
          else if (settingsToUpdate.timeserver_type === NTP_SETTINGS_INDEX.DEEPGRID)
          {
            settingsToUpdate.timeserver_type = ResourceLang.deepgrid
          }
        }
    }

    const translatedSettings = cloneDeep(dataToBeUpdated);
    
    const renderContent = () => {
        return (<Grid container spacing={2} style={{marginTop: 8}}>
            <Grid item xs={12} style={{height: 420}}>
                <InputLabel>{ResourceLang.devices}</InputLabel>
                {renderDeviceItems()}
                <InputLabel>{ResourceLang.data_update_apn}</InputLabel>
                {renderNTPSettings()}
            </Grid>
            </Grid>
            );
    }

    const renderDeviceItems = () => {
        const gridRowGetter = (index) => { return devices[index]; }
        const keys = Object.keys(devices[0]);
        var columns = keys.map(key => ({label: ResourceLang[key], dataKey:key}))
            .filter(x => x.dataKey === DeviceKeysEnum.SERIAL_NUMBER );
        
        return (<div  data-testid='sdsTable' style={{height: 200, width: '100%', marginTop: 8}}>
            <RawVirtualizedGrid
                rowCount={devices.length}
                rowGetter={gridRowGetter}
                columns={columns}
               />
        </div>);
    }

    const renderNTPSettings = () => {
        translateSettingsToTableRender(translatedSettings[0])
        const gridRowGetter = (index) => { return translatedSettings[index]; }
        const columns = [
            {
              columnWidth: 75,
              label: ResourceLang.timeserver_url,
              dataKey: DEVICE_SETTINGS_KEYS.TIMESERVER_URL,
              disableSort: false,
            },
            {
                columnWidth: 75,
                label: ResourceLang.timeserver_port,
                dataKey: DEVICE_SETTINGS_KEYS.TIMESERVER_PORT,
                disableSort: false,
            },
            {
                columnWidth: 75,
                label: ResourceLang.timeserver_protocol,
                dataKey: DEVICE_SETTINGS_KEYS.TIMESERVER_PROTOCOL,
                disableSort: false,
            },
            {
                columnWidth: 75,
                label: ResourceLang.timeserver_type,
                dataKey: DEVICE_SETTINGS_KEYS.TIMESERVER_TYPE,
                disableSort: false,
            }
          ];
        
        return (<div  data-testid='sdsTable' style={{height: 200, width: '100%', marginTop: 8}}>
            <RawVirtualizedGrid
                rowCount={translatedSettings.length}
                rowGetter={gridRowGetter}
                columns={columns}
               />
        </div>);
    }


    return (<>{renderContent()}</>)
}

UpdateNTPSettings.propTypes = {
    devices: PropTypes.array.isRequired,
    dataToBeUpdated: PropTypes.array.isRequired
};

export default UpdateNTPSettings;