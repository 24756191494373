import React from 'react';
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { setLoggedUser, SetUserData } from '../../redux/actions/UserActions';
import ResourceLang from '../../resources/Language';
import SessionServices from '../../utils/SessionServices';
import { requestLogout } from '../../utils/APIRequester';
import SideMenu from './SideMenu';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import ApartmentIcon from '@material-ui/icons/Apartment';
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));


const NavBar = ({match, location, history, ...props}) => {
  const classes = useStyles();
  const [sideMenu, setSideMenu] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const envName = location.state ? location.state.context : "";

  const renderUserMenu = () => {
    return (<div>
        <IconButton
          aria-label="account of current user"
          aria-controls="user-menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <Menu id="user-menu-appbar"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          getContentAnchorEl={null}
          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
          transformOrigin={{vertical: 'top', horizontal: 'center'}}
          onClose={handleClose}
        >
          <MenuItem onClick={handleLogout}>{ResourceLang.logout}</MenuItem>
        </Menu>
    </div>);
  }

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try{
      await requestLogout();
    }
    catch {}

    SessionServices.cleanAuthContext();
    props.logout();
    props.clearUserData();
  }


  const renderAppMenu = () => {
    return (<IconButton id="app-menu-appbar"
      className={classes.menuButton}
      edge="start" 
      color="inherit"
      aria-label="menu"
      onClick={toggleSideMenu(!sideMenu)}
    >
      <MenuIcon />
    </IconButton>)
  }

  const toggleSideMenu = (isOpen) => (event) => {
    setSideMenu(isOpen);
  };


  return (<div className={classes.root}>
      <AppBar position="static">
        <Toolbar variant="dense">
          {renderAppMenu()}
          <Typography variant="h6" className={classes.title}>
            {ResourceLang.eneida_backoffice}
          </Typography>
          {envName ? <><ApartmentIcon fontSize="small" />&nbsp;{envName}&nbsp;&nbsp;</> : <></>}
          {renderUserMenu()}
        </Toolbar>
      </AppBar>
      <SideMenu open={sideMenu}
        envID={match.params.envId}
        toggleMenu={toggleSideMenu}
        history={history}/>
  </div>);
}


NavBar.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
	return {
        userStatus: state.userStatus,
        userData: state.userData
    };
};

const mapDispatchToProps = (dispatch) => {
	return {
    logout: () => dispatch(setLoggedUser(false)),
    clearUserData:() => dispatch(SetUserData({})),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NavBar));