import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Stepper, Step, StepLabel } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));


const DialogStepper = ({
  activeStep, steps, stepProps = {}, labelProps = {}, ...other
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root} data-testid='stepper'>
      <Stepper activeStep={activeStep} {...other}>
        {steps.map((label) => {
          return (<Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
          </Step>);
        })}
      </Stepper>
    </div>
  );
}

DialogStepper.propTypes = {
    activeStep: PropTypes.number.isRequired,
    steps: PropTypes.array.isRequired
};

export default DialogStepper;
