import React from 'react';
import { Router, Switch, Route } from "react-router";
import LoginPage from '../pages/LoginPage';
import { createBrowserHistory } from 'history';
import ScrollToTop from './ScrollToTop';
import ProtectedRoutes from './ProtectedRoutes';

const history = createBrowserHistory();

const Routes = () => {
    return (
        <Router history={history}>
          <ScrollToTop>
            <Switch>
              <Route path="/" exact render={(props) => <LoginPage />} />
              <ProtectedRoutes history={history}/>
            </Switch>
          </ScrollToTop>
        </Router>
    );
}

export default Routes