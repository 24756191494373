import React, { Component } from 'react';
//import backgroundImg from '../../../styles/img/deepgrid_one.jpg';
import './css/main.css';
import './css/util.css';
import LogoBackOffice from '../../../resources/img/backoffice_icon.png';
import 'material-design-iconic-font/dist/css/material-design-iconic-font.css';
import CircularLoading from '../../../components/loadings/CircularLoading';
import { withStyles } from '@material-ui/core/styles';
import { requestLogin } from '../../../utils/APIRequester';
import ResourceLang from '../../../resources/Language';
import MessageSnackbar from '../../../components/notifications/MessageSnackbar';


const styles = theme => ({
    progress: {
        zIndex:"50",
        position:'relative' ,
        color:'#F1c419',
        width: '50px',
        height: '50px',
    },
});


class LoginForm extends Component{  
    constructor(props){
        super(props);
        this.state = {
            username: {
                value: '',
                isValid:true,
            },
            password: {
                value: '',
                isValid: true,
            },
            requestFail: false,
            isLoading: false,
            keepLogged: false,
            requestFailMessage: ''
        }
    }

    onChangeUsername = (event) => {
        let username = {
            value: event.target.value,
            isValid:true,
        }

        this.setState({
            username: username,
        })
    }

    onChangePassword = (event) => {
        let password = {
            value: event.target.value,
            isValid: true
        }
        this.setState({
            password: password,
        })
    }

    onClickEnter = (event) =>{
        if(event.charCode === 13){
            this.onClicklogin(event);
          } 
    }


    validateForm = (event) =>{
        if(this.state.username.value !== '' && this.state.password.value !== '')
            this.submit();
        else{
    
            let username = this.state.username;
            let password = this.state.password;

            if(username.value === ''){
                username.isValid = false;
            }

            if(password.value === ''){
                password.isValid = false;
            }

            this.setState({
                username: username,
                password: password,
            });
        }
    }

    
    validateEmail(){
        return /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.state.username.value);
    }


    submit = () =>{
        const username = this.state.username.value;
        const password = this.state.password.value;
        
        let promise = requestLogin(username, password);
        
        this.setState({
            isLoading: true,
        });

        promise.then(response =>{
            if(response !== undefined) {               
                let user = {
                    name: username,
                    token: response.access_token,
                    token_type: response.token_type
                };
            
                const result = this.props.login(true, user, this.state.keepLogged);
                
                if (result && result.success) {
                    this.setState({ isLoading:false });
                }
                else {
                    this.loginFailed(
                        result.error || ResourceLang.username_password_incorrect
                    );
                }
            }
            else {
                this.loginFailed(ResourceLang.username_password_incorrect);
            }
        })
        .catch((error) => {
            this.loginFailed(error.message || ResourceLang.username_password_incorrect);
        });
    }

    loginFailed = (failMessage) => {
        this.setState({
            isLoading:false,
            requestFailMessage: failMessage,
            requestFail: true,
        });
    }

    submitForm = (event) =>{
        event.preventDefault();
    }

    getClassNameInput(field){
        if(field.isValid) {
            return "wrap-input100 validate-input";
        }
        else{
            return "wrap-input100 validate-input alert-validate";
        }
    }


    renderSubmit(){
        if(this.state.isLoading){
            return(<CircularLoading />);
        }
        else{
            return(
                <button className="login100-form-btn" onClick={this.validateForm}>
                    {ResourceLang.login}
                 </button>
            );
        }
    }

    onChangeKeepLogged = () =>{
       this.setState({ keepLogged: !this.state.keepLogged });
    }

    onEnterPressed = (event) =>{
        if(event.key === 'Enter'){
            this.validateForm();
        }
    }


    renderSnackbar = () => {
        return (<MessageSnackbar open={this.state.requestFail}
            message={this.state.requestFailMessage}
            variant={'error'}
            onClose={this.handleSnackbarClose}/>);
    }

    handleSnackbarClose = () => {
        this.setState({ requestFail: false });
    }


    render(){
        
        let disabled = (this.state.isLoading) ? 'disabled' : '';
        let readOnly = (this.state.isLoading) ? 'readOnly' : '';

        return (
            <div className="limiter">
                <div className="container-login100" style={{backgroundColor:'#000000'}}>
                    <div className="wrap-login100">

                        <div className="fogin100-form validate-form">
                            
                            <span className="login100-form-logo">
                                <img alt="eneida" src={LogoBackOffice} width="100px"/>
                            </span>

                            <span className="login100-form-title p-b-34 p-t-27">
                                {ResourceLang.eneida_backoffice}
                            </span>

                            <div className={this.getClassNameInput(this.state.username)}
                                data-validate={ResourceLang.missing_username}>
                                <input value={this.state.username.value} 
                                    onChange={this.onChangeUsername} 
                                    className="input100" type="text" 
                                    name="email" 
                                    placeholder={ResourceLang.email}
                                    readOnly={readOnly}
                                    onKeyPress={this.onEnterPressed} 
                                    disabled={disabled}/>
                                <span className="focus-input100" data-placeholder="&#xf207;"></span>
                            </div>

                            <div className={this.getClassNameInput(this.state.password)}
                                data-validate={ResourceLang.missing_password}>
                                <input className="input100" 
                                    type="password" 
                                    name="pass" 
                                    placeholder={ResourceLang.password} 
                                    value={this.state.password.value}
                                    onChange={this.onChangePassword}
                                    onKeyPress={this.onEnterPressed}
                                    readOnly={readOnly}
                                    disabled={disabled}/>
                                <span className="focus-input100" data-placeholder="&#xf191;"></span>
                            </div>

                            <div className="contact100-form-checkbox">
                                <input  disabled={disabled} className="input-checkbox100" id="ckb1" type="checkbox" 
                                name="remember-me" checked={this.state.keepLogged} onChange={this.onChangeKeepLogged}/>
                                
                                <label className="label-checkbox100" htmlFor="ckb1">
                                    {ResourceLang.keep_me_logged}
                                </label>
                            </div>
                            <div className="container-login100-form-btn">
                                {this.renderSubmit()}
                            </div>
                            <div className="text-center p-t-60" />
                        </div>
                    </div>
                </div>
                
                {this.renderSnackbar()}
            </div>
        );
    }
}
export default (withStyles(styles)(LoginForm));
