import React, {useState} from 'react';
import { createPortal } from 'react-dom';
import './PageLoading.css';


const Modal = ({children, classes, ...other}) =>{
    const modalRoot = document.createElement('div');
    const [div] = useState(modalRoot);

    React.useLayoutEffect(() => {
        document.body.appendChild(div);
        return () => { document.body.removeChild(div); }
    });

    return(
        createPortal(children, div, 'loading')
    );
}

const PageLoading = (props) =>{
    return(<Modal>
        <div className="loading-modal"  data-testid="page-loading">
        <div id="fountainG" style={{top: "50%"}}>
            <div id="fountainG_1" className="fountainG"></div>
            <div id="fountainG_2" className="fountainG"></div>
            <div id="fountainG_3" className="fountainG"></div>
            <div id="fountainG_4" className="fountainG"></div>
            <div id="fountainG_5" className="fountainG"></div>
            <div id="fountainG_6" className="fountainG"></div>
            <div id="fountainG_7" className="fountainG"></div>
            <div id="fountainG_8" className="fountainG"></div>
        </div>
        </div>
    </Modal>);
} 

export default PageLoading;