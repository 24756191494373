import React from 'react';
import ResourceLang from '../../resources/Language';
import PropTypes from 'prop-types';
import { Grid, InputLabel } from '@material-ui/core';
import RawVirtualizedGrid from '../tables/RawVirtualizedGrid'

const UploadReview = ({devices, next_version}) => {

    const DeviceKeysEnum = {
        UPDATE_VERSION: 'update_firmware_version',
        UPDATE_REQUEST_DATE_VERSION: 'update_request_date'
  }
  
    const renderContent = () => {
    return (<Grid container spacing={2}>
            <Grid item xs={12}>
                
            </Grid>
            <Grid item xs={12}>
                <b>{ResourceLang.next_version}</b> {next_version}
                <InputLabel style={{marginTop: 16}}>{ResourceLang.devices}</InputLabel>
                {renderDeviceItems()}
            </Grid>
        </Grid>
        );
    }

    const renderDeviceItems = () => {
        const gridRowGetter = (index) => { return devices[index]; }
        const keys = Object.keys(devices[0]);
        var columns = keys.map(key => ({label: ResourceLang[key], dataKey:key}))
            .filter(x => x.dataKey !== DeviceKeysEnum.UPDATE_VERSION 
                && x.dataKey !== DeviceKeysEnum.UPDATE_REQUEST_DATE_VERSION);
        
        return (<div data-testid='sdsTable' style={{height: 200, width: '100%', marginTop: 8}}>
            <RawVirtualizedGrid
                rowCount={devices.length}
                rowGetter={gridRowGetter}
                columns={columns}
               />
        </div>);
    }
    return (<>{renderContent()}</>)
}

UploadReview.propTypes = {
    devices: PropTypes.object.isRequired,
    next_version: PropTypes.string.isRequired
};

export default UploadReview;