import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { withRouter } from 'react-router'
import Button from '@material-ui/core/Button'
import ResourceLang from '../../resources/Language'
import DialogStepper from '../stepper/DialogStepper'
import { FirmwareAPI } from '../../utils/APIRequester'
import CircularLoading from '../loadings/CircularLoading'
import SelectVersions from './SelectVersions'
import UpdateReview from './UpdateReview'
import SelectDevices from './SelectDevices'
import PropTypes from 'prop-types'
import { DEVICE_KEYS_ENUM } from '../../utils/Enums'
import { getLocalDateTimeString } from '../../utils/DateServices'

const SELECT_DEVICES_STEP = 0
const SELECT_VERSION_STEP = 1
const REVIEW_STEP = 2

function getSteps() {
  return [
    ResourceLang.device_to_be_upgraded,
    ResourceLang.version_choose,
    ResourceLang.review_and_confirmation,
  ]
}

const fwFilenameToVersion = (microController, filename) => {
  let versionName = filename.substr(0, filename.indexOf('.'))

  try {
    if (microController.toUpperCase() === 'COM' && versionName.length >= 3) {
      versionName = parseInt(versionName.substr(0, 3))
    } else if (
      microController.toUpperCase() === 'MEA' &&
      versionName.length >= 6
    ) {
      versionName = parseInt(versionName.substr(3, 3))
    }
  } catch (ex) {
    console.error(ex.message)
    return ResourceLang.invalid_fw_version
  }

  if (Number.isNaN(versionName)) {
    return ResourceLang.invalid_fw_version
  }

  return versionName
}

const UpdateFirmwarePanel = ({
  match,
  deviceTypeName,
  deviceVersion,
  microController,
  closePanel,
}) => {
  const [state, setState] = React.useState({
    devices: [],
    fullDevices: [],
    activeStep: 0,
    disableNext: true,
    disableBack: true,
    error: false,
    isLoading: true,
    devicesIsLoading: true,
    updateRequestVersion: undefined,
    selectedDevices: [],
    availableVersions: [],
    filteredAvailableVersions: [],
    filterCurrentVersion: null,
    filterUpdateVersion: null,
    currentVersions: [],
    updateVersions: [],
  })

  const envID = match.params.envId
  const steps = getSteps()

  const closeWithError = React.useCallback(
    (message) => {
      closePanel('error', message)
    },
    [closePanel]
  )

  const prepareFirmwareVersions = React.useCallback(
    (rawVersions) => {
      const versions = rawVersions.map(function (curVersion) {
        var version = {}

        version[DEVICE_KEYS_ENUM.UPDATE_REQUEST_DATE_VERSION] =
          getLocalDateTimeString(curVersion.creation_date)
        version[DEVICE_KEYS_ENUM.CURRENT_VERSION] = fwFilenameToVersion(
          microController,
          curVersion.filename
        )

        return version
      })

      if (versions.length === 0) {
        closeWithError(ResourceLang.no_versions)
        return undefined
      }

      return versions
    },
    [closeWithError, microController]
  )

  const prepareDevices = React.useCallback(
    (rawDevices) => {
      const devices = rawDevices.map(function (curDevice) {
        var device = {}
        device[DEVICE_KEYS_ENUM.DEVICE_ID] = curDevice.device_id
        device[DEVICE_KEYS_ENUM.SERIAL_NUMBER] = curDevice.serial_number

        var curFirmware = curDevice.device_firmware.filter(function (
          curMicrocontroller
        ) {
          return curMicrocontroller.firmware_id === microController
        })

        device[DEVICE_KEYS_ENUM.CURRENT_VERSION] =
          curFirmware[0].current_firmware_version
        device[DEVICE_KEYS_ENUM.UPDATE_VERSION] =
          curFirmware[0].update_firmware_version
        device[DEVICE_KEYS_ENUM.UPDATE_REQUEST_DATE_VERSION] =
          curFirmware[0].update_request_date
        device[DEVICE_KEYS_ENUM.FIRMWARE_DOWNLOAD_ATEMPTS] =
          curFirmware[0].firmware_download_atempts
        device[DEVICE_KEYS_ENUM.FIRMWARE_ID] = curFirmware[0].firmware_id

        return device
      })

      if (devices.length === 0) {
        closeWithError(ResourceLang.no_devices)
        return undefined
      }

      return devices
    },
    [closeWithError, microController]
  )

  const getCurrentVersions = () => {
    var currentVersions = []
    var devices = state.fullDevices

    if (
      state.filterUpdateVersion &&
      state.filterUpdateVersion !== null &&
      state.filterUpdateVersion.length > 0 &&
      state.filterUpdateVersion[0].value
    ) {
      devices = devices.filter(function (f) {
        return f.update_firmware_version === state.filterUpdateVersion[0].value
      })
    }

    devices.forEach(function (device) {
      var curr = currentVersions.findIndex(
        (x) => x.name == device.current_firmware_version
      )
      if (curr <= -1) {
        currentVersions.push({
          name: device.current_firmware_version,
          value: device.current_firmware_version,
        })
      }
    })
    return versionsSort(currentVersions)
  }

  const getUpdateVersions = () => {
    var updateVersions = []
    var devices = state.fullDevices

    if (
      state.filterCurrentVersion &&
      state.filterCurrentVersion !== null &&
      state.filterCurrentVersion.length > 0 &&
      state.filterCurrentVersion[0].value
    ) {
      devices = devices.filter(function (f) {
        return (
          f.current_firmware_version === state.filterCurrentVersion[0].value
        )
      })
    }

    devices.forEach(function (device) {
      var curr = updateVersions.findIndex(
        (x) => x.name == device.update_firmware_version
      )
      if (curr <= -1) {
        updateVersions.push({
          name: device.update_firmware_version,
          value: device.update_firmware_version,
        })
      }
    })
    return versionsSort(updateVersions)
  }

  const versionsSort = (versions) => {
    return versions.sort(function (a, b) {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    })
  }

  const handleFilters = (filters) => {
    filters.forEach((filter) => {
      if (
        filter.key === 'current_version' &&
        filter.value !== state.filterCurrentVersion
      ) {
        setState((prevState) => ({
          ...prevState,
          filterCurrentVersion: filter.value,
        }))
      }

      if (
        filter.key === 'update_version' &&
        filter.value !== state.filterUpdateVersion
      ) {
        setState((prevState) => ({
          ...prevState,
          filterUpdateVersion: filter.value,
        }))
      }
    })
  }

  useEffect(() => {
    if (
      state.filterCurrentVersion &&
      state.filterCurrentVersion !== null &&
      state.filterCurrentVersion.length > 0 &&
      state.filterCurrentVersion[0].value
    ) {
      var devices = state.devices.filter(function (f) {
        return (
          f.current_firmware_version === state.filterCurrentVersion[0].value
        )
      })
      setState((prevState) => ({ ...prevState, devices: devices }))
    } else if (
      state.filterCurrentVersion &&
      state.filterCurrentVersion !== null &&
      state.filterCurrentVersion.length === 0
    ) {
      if (
        state.filterUpdateVersion &&
        state.filterUpdateVersion !== null &&
        state.filterUpdateVersion.length > 0 &&
        state.filterUpdateVersion[0].value
      ) {
        var devices = state.fullDevices.filter(function (f) {
          return (
            f.update_firmware_version === state.filterUpdateVersion[0].value
          )
        })
        setState((prevState) => ({ ...prevState, devices: devices }))
      } else {
        setState((prevState) => ({ ...prevState, devices: state.fullDevices }))
      }
    }
  }, [state.filterCurrentVersion])

  useEffect(() => {
    if (
      state.filterUpdateVersion &&
      state.filterUpdateVersion !== null &&
      state.filterUpdateVersion.length > 0 &&
      state.filterUpdateVersion[0].value
    ) {
      var devices = state.devices.filter(function (f) {
        return f.update_firmware_version === state.filterUpdateVersion[0].value
      })
      setState((prevState) => ({ ...prevState, devices: devices }))
    } else if (
      state.filterUpdateVersion &&
      state.filterUpdateVersion !== null &&
      state.filterUpdateVersion.length === 0
    ) {
      if (
        state.filterCurrentVersion &&
        state.filterCurrentVersion !== null &&
        state.filterCurrentVersion.length > 0 &&
        state.filterCurrentVersion[0].value
      ) {
        var devices = state.fullDevices.filter(function (f) {
          return (
            f.current_firmware_version === state.filterCurrentVersion[0].value
          )
        })
        setState((prevState) => ({ ...prevState, devices: devices }))
      } else {
        setState((prevState) => ({
          ...prevState,
          devices: state.fullDevices,
        }))
      }
    }
  }, [state.filterUpdateVersion])

  const getAllData = React.useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      devicesIsLoading: true,
    }))

    Promise.all([
      FirmwareAPI.getDevicesByFamily(
        envID,
        deviceTypeName,
        deviceVersion,
        microController
      ),
      FirmwareAPI.getAllVersions(
        envID,
        deviceTypeName,
        deviceVersion,
        microController
      ),
    ])
      .then((responses) => {
        var devices = []
        var versions = []
        if (responses[0] && Array.isArray(responses[0])) {
          devices = prepareDevices(responses[0])
        }
        if (responses[1] && Array.isArray(responses[1])) {
          versions = prepareFirmwareVersions(responses[1])
        }
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          devicesIsLoading: false,
          devices: devices,
          fullDevices: devices,
          availableVersions: versions,
          filteredAvailableVersions: versions,
        }))
      })
      .catch((error) => {
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          devicesIsLoading: false,
        }))
        closeWithError(ResourceLang.generic_error)
      })
  }, [
    prepareDevices,
    closeWithError,
    prepareFirmwareVersions,
    envID,
    deviceTypeName,
    deviceVersion,
    microController,
  ])

  useEffect(() => {
    const load = async () => {
      getAllData()
    }

    load()
  }, [getAllData])

  const updateDeviceFirmware = (selectedDevices) => {
    const devices = selectedDevices.map(function (curDevice) {
      curDevice[DEVICE_KEYS_ENUM.UPDATE_VERSION] = state.updateRequestVersion
      return curDevice
    })

    const formData = new FormData()
    formData.append('devices', JSON.stringify(devices))
    FirmwareAPI.updateDeviceFirmware(envID, formData)
      .then((response) => {
        setState((prevState) => ({
          ...prevState,
          selectedDevices: devices,
        }))
      })
      .catch((error) => {
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
        }))
        closePanel('error', error)
      })
  }

  const handleNext = () => {
    if (state.activeStep === REVIEW_STEP) {
      updateDeviceFirmware(state.selectedDevices)
      setState((prevState) => ({
        ...prevState,
        activeStep: 0,
      }))

      closePanel('success', ResourceLang.update_with_success)
    } else if (state.activeStep === SELECT_VERSION_STEP) {
      setState((prevState) => ({
        ...prevState,
        activeStep: state.activeStep + 1,
        disableNext: false,
      }))
    } else {
      setState((prevState) => ({
        ...prevState,
        activeStep: state.activeStep + 1,
        disableBack: false,
        disableNext: true,
      }))
    }
  }

  const handleBack = () => {
    if (state.activeStep === SELECT_VERSION_STEP) {
      setState((prevState) => ({
        ...prevState,
        selectedDevices: [],
        filteredAvailableVersions: state.availableVersions,
        disableBack: true,
        disableNext: true,
      }))
    } else if (state.activeStep === REVIEW_STEP) {
      setState((prevState) => ({
        ...prevState,
        updateRequestVersion: undefined,
        disableNext: true,
      }))
    }
    setState((prevState) => ({
      ...prevState,
      activeStep: state.activeStep - 1,
    }))
  }

  const setUploadRequestVersion = (requestVersion) => {
    if (requestVersion !== undefined) {
      setState((prevState) => ({
        ...prevState,
        updateRequestVersion: requestVersion,
        disableNext: false,
      }))
    }
  }

  const getHigherFirmwareVersionsToUpdate = (devices) => {
    return devices.reduce(
      (maxVersion, curDevice) =>
        curDevice[DEVICE_KEYS_ENUM.CURRENT_VERSION] > maxVersion
          ? curDevice[DEVICE_KEYS_ENUM.CURRENT_VERSION]
          : maxVersion,
      devices[0][DEVICE_KEYS_ENUM.CURRENT_VERSION]
    )
  }

  const checkAvailableVersionsForDevice = (devices) => {
    var canClickNextButton = false
    var actualVersions = state.availableVersions
    var maxVersion = undefined
    if (devices.length > 0) {
      maxVersion = getHigherFirmwareVersionsToUpdate(devices)
      actualVersions = actualVersions.filter(
        (item) => Number(item.current_firmware_version) > Number(maxVersion)
      )

      if (actualVersions.length > 0) {
        canClickNextButton = true
      }
      setState((prevState) => ({
        ...prevState,
        selectedDevices: devices,
        filteredAvailableVersions: actualVersions,
        disableNext: !canClickNextButton,
      }))
    } else {
      setState((prevState) => ({
        ...prevState,
        selectedDevices: devices,
        filteredAvailableVersions: actualVersions,
        disableNext: true,
      }))
    }
    return canClickNextButton
  }

  const setDeviceSelected = (rowData, isChecked) => {
    var devices = state.selectedDevices

    if (isChecked === true) {
      devices.push(rowData)
    } else {
      devices = state.selectedDevices.filter(function (device) {
        return device.device_id !== rowData.device_id
      })
    }

    return devices
  }

  const handleSelection = (rowData, isChecked) => {
    if (rowData !== undefined) {
      const devices = setDeviceSelected(rowData, isChecked)
      checkAvailableVersionsForDevice(devices)
    }
  }

  const handleAllSelections = (rowData, isChecked) => {
    var devices = []

    if (isChecked === true) {
      devices = rowData
    }

    checkAvailableVersionsForDevice(devices)
  }

  const renderBody = () => {
    switch (state.activeStep) {
      case SELECT_DEVICES_STEP:
        return (
          <SelectDevices
            devices={state.devices}
            selectedDevices={state.selectedDevices}
            availableVersions={state.availableVersions}
            filteredAvailableVersions={state.filteredAvailableVersions}
            handleSelection={handleSelection}
            handleAllSelections={handleAllSelections}
            isLoading={state.devicesIsLoading}
            currentVersions={getCurrentVersions()}
            updateVersions={getUpdateVersions()}
            handleFilters={handleFilters}
          />
        )
      case SELECT_VERSION_STEP:
        return (
          <SelectVersions
            availableVersions={state.filteredAvailableVersions}
            versionSelected={setUploadRequestVersion}
          />
        )
      case REVIEW_STEP:
        return (
          <UpdateReview
            devices={state.selectedDevices}
            next_version={state.updateRequestVersion}
          />
        )
      default:
        return <></>
    }
  }

  const renderContent = () => {
    return (
      <div style={{ minHeight: 300 }}>
        <DialogStepper activeStep={state.activeStep} steps={steps} />
        {renderBody()}
        {state.isLoading === false ? <></> : <CircularLoading />}
      </div>
    )
  }

  const renderFooter = () => {
    return (
      <div>
        <div>
          <div style={{ float: 'right' }}>
            <Button
              disabled={state.disableBack}
              onClick={handleBack}
              style={{ marginRight: '10px' }}
            >
              <>{ResourceLang.back}</>
            </Button>
            <Button
              variant='contained'
              color='primary'
              onClick={handleNext}
              style={{ marginRight: '10px' }}
              disabled={state.disableNext}
            >
              {state.activeStep === steps.length - 1 ? 'submit' : 'next'}
            </Button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {renderContent()}
        {renderFooter()}
      </Grid>
    </Grid>
  )
}

UpdateFirmwarePanel.propTypes = {
  match: PropTypes.object.isRequired,
  deviceTypeName: PropTypes.string.isRequired,
  deviceVersion: PropTypes.string.isRequired,
  microController: PropTypes.string.isRequired,
  closePanel: PropTypes.func.isRequired,
}

export default withRouter(UpdateFirmwarePanel)
