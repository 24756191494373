import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';


const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const MessageSnackbar = ({
    open = false, message = '', variant = 'error', autoHideDuration, onClose
}) => {
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        onClose();
      };

    return (<Snackbar open={open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        autoHideDuration={autoHideDuration || 4000}
        onClose={handleClose}
    >
        <Alert onClose={handleClose} severity={variant}>
            {message}
        </Alert>
    </Snackbar>);
}


MessageSnackbar.propTypes = {
    open: PropTypes.bool,
    message: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
    autoHideDuration: PropTypes.number,
    onClose: PropTypes.func
};

export default MessageSnackbar;