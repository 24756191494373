const DEVICE_KEYS = {
    CURRENT_VERSION: 'current_firmware_version',
    UPDATE_VERSION: 'update_firmware_version',
    UPDATE_REQUEST_DATE_VERSION: 'update_request_date',
    DEVICE_ID: 'device_id',
    SERIAL_NUMBER: 'serial_number',
    FIRMWARE_DOWNLOAD_ATEMPTS: 'firmware_download_atempts',
    FIRMWARE_ID: 'firmware_id'
}

const SETTINGS_TYPE = {
  APN: 1,
  NTP: 2
}

const DEVICE_VERSION_VALUES = {
  V1:"V1",
  V2:"V2",
  V3:"V3",
  V4: "V4"
};

export const DEVICE_KEYS_ENUM = Object.freeze(DEVICE_KEYS);
export const DEVICE_VERSION_VALUES_ENUM = Object.freeze(DEVICE_VERSION_VALUES);
export const SETTINGS_TYPE_ENUM = Object.freeze(SETTINGS_TYPE);