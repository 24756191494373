import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';


const styles = theme => ({
    progress: {
        zIndex:"50",
        position:'absolute',
        top: "50%",
        left: "50%",
    },
});


const CircularLoading = ({classes}) => {
    return <CircularProgress color="secondary"
        size={50} thickness={4}
        className={classes.progress} />
}


CircularLoading.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CircularLoading);