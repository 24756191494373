import React, { useState } from 'react';
import {InputGroup, InputGroupAddon, InputGroupText, Input, FormFeedback } from 'reactstrap';
import { makeStyles } from '@material-ui/core/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';


const useStyles = makeStyles((theme) => ({
    passwordToogle: {
        cursor: 'pointer'
    }
}));


const usePasswordToggle = () => {
    const [visible, setVisiblity] = useState(false);

    const Icon = (
        visible === true ?
        <VisibilityOffIcon style={{ fontSize: 18 }}
            onClick={() => setVisiblity(visiblity => !visiblity)} />
        : <VisibilityIcon style={{ fontSize: 18 }}
            onClick={() => setVisiblity(visiblity => !visiblity)} />
    );

    const InputType = visible ? "text" : "password";

    return [InputType, Icon];
};


const InputPassword = ({
    value = "", readOnly = false, onChange = undefined, feedbackText = undefined, ...others
}) => {
    const classes = useStyles();
    const [PasswordInputType, ToggleIcon] = usePasswordToggle();

    return (<>
        <InputGroup size="sm">
            <Input {...others}
                value={value}
                readOnly={readOnly}
                type={readOnly ? 'password' : PasswordInputType}
                onChange={onChange}/>
                <InputGroupAddon addonType="append">
                <InputGroupText>{readOnly ? null : <span className={classes.passwordToogle}>{ToggleIcon}</span>}</InputGroupText>
                </InputGroupAddon>
                {feedbackText ? <FormFeedback>{feedbackText}</FormFeedback> : null}
        </InputGroup>
    </>);
}


export default InputPassword;