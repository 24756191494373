import axios from 'axios'
import { setLoggedUser, SetUserData } from '../redux/actions/UserActions'
import { store } from '../redux/store/AppProvider'
import SessionServices from './SessionServices'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

const sendAuthRequest = (config) => {
  config.withCredentials = true
  config.headers = {
    'Content-type': 'application/json',
    Authorization: 'Bearer ' + SessionServices.getAuthToken(),
  }

  return sendRequest(config)
}

const sendRequest = (config) => {
  return api(config)
    .then((response) => handleThen(response))
    .catch((error) => handleCatch(error))
}

const handleThen = (response) => {
  const success = response.status >= 200 && response.status <= 299
  return success ? response.data : undefined
}

const handleCatch = (error) => {
  console.error(error)
  if (!error.response) return Promise.reject(error)

  const response = error.response
  const result = { status: response.status, message: '' }

  if (response.status === 503) {
    result.message = 'SERVICE UNAVAILABLE'
  } else if (response.status === 403) {
    result.message = 'PERMISSION DENIED'
  } else if (response.status === 401) {
    result.message = 'USER NOT AUTHENTICATED'
    store.dispatch(setLoggedUser(false))
    store.dispatch(SetUserData({}))
  } else {
    result.message = 'UNEXPECTED ERROR'
    result.data = response.data
  }

  console.error(result.message)
  return Promise.reject(result)
}

export const requestLogin = (username, password) => {
  const data = new URLSearchParams()
  data.append('username', username)
  data.append('password', password)
  data.append('grant_type', 'password')
  data.append('client_id', 'backoffice.webapp')

  return sendRequest({
    method: 'POST',
    url: '/api/account/login/token',
    data: data,
    headers: { 'Content-type': 'application/x-www-form-urlencoded' },
  })
}

export const requestLogout = () => {
  return sendAuthRequest({ method: 'POST', url: '/api/account/logout' })
}

export const EnvironmentAPI = {
  getAll: () => {
    return sendAuthRequest({ method: 'GET', url: 'api/v1/environment/all' })
  },

  getOne: (envId) => {
    return sendAuthRequest({
      method: 'GET',
      url: `api/v1/environment/${envId}`,
    })
  },

  create: (data) => {
    return sendAuthRequest({
      method: 'POST',
      url: 'api/v1/environment',
      data: data,
    })
  },

  update: (envId, data) => {
    return sendAuthRequest({
      method: 'PUT',
      url: `api/v1/environment/${envId}`,
      data: data,
    })
  },

  delete: (envId) => {
    return sendAuthRequest({
      method: 'DELETE',
      url: `api/v1/environment/${envId}`,
    })
  },
}

export const FirmwareAPI = {
  getStructure: (envId) => {
    return sendAuthRequest({
      method: 'GET',
      url: `api/v1/firmware/structure/${envId}`,
    })
  },
  getAllVersions: (envId, deviceTypeName, deviceVersion, microController) => {
    return sendAuthRequest({
      method: 'GET',
      url: `api/v1/firmware/files/${envId}/${deviceTypeName}/${deviceVersion}/${microController}`,
    })
  },
  getGPRSSettingsByDevice: (envId, deviceId) => {
    return sendAuthRequest({
      method: 'GET',
      url: `api/v1/firmware/devices/${envId}/gprs/${deviceId}`,
    })
  },
  getDevicesByFamily: (
    envId,
    deviceTypeName,
    deviceVersion,
    microController
  ) => {
    return sendAuthRequest({
      method: 'GET',
      url: `api/v1/firmware/devices/${envId}/${deviceTypeName}/${deviceVersion}/${microController}`,
    })
  },
  getDevicesFamilyByDeviceId: (envId, deviceId) => {
    return sendAuthRequest({
      method: 'GET',
      url: `api/v1/firmware/devices/${envId}/${deviceId}/family`,
    })
  },
  uploadFileToServer: (
    envId,
    deviceTypeName,
    deviceVersion,
    microController,
    formData
  ) => {
    return sendAuthRequest({
      method: 'POST',
      url: `api/v1/firmware/upload/${envId}/${deviceTypeName}/${deviceVersion}/${microController}`,
      data: formData,
    })
  },
  getAllDevices: (envId, data) => {
    return sendAuthRequest({
      method: 'POST',
      url: `api/v1/firmware/devices/${envId}`,
      data: data,
    })
  },
  getCSVExportFile: (formData) => {
    return sendAuthRequest({
      method: 'POST',
      url: `api/v1/firmware/devices/export`,
      data: formData,
    })
  },
  putGPRSSettingsByDevice: (envId, deviceId, formData) => {
    return sendAuthRequest({
      method: 'PUT',
      url: `api/v1/firmware/devices/${envId}/gprs/${deviceId}`,
      data: formData,
    })
  },
  putNTPSettingsByDevice: (envId, deviceId, formData) => {
    return sendAuthRequest({
      method: 'PUT',
      url: `api/v1/firmware/devices/${envId}/ntp/${deviceId}`,
      data: formData,
    })
  },
  putGPRSSettingsInBatchByDevice: (envId, formData) => {
    return sendAuthRequest({
      method: 'PUT',
      url: `api/v1/firmware/devices/${envId}/gprs/batch`,
      data: formData,
    })
  },
  putNTPSettingsInBatchByDevice: (envId, formData) => {
    return sendAuthRequest({
      method: 'PUT',
      url: `api/v1/firmware/devices/${envId}/ntp/batch`,
      data: formData,
    })
  },
  updateDeviceFirmware: (envId, formData) => {
    return sendAuthRequest({
      method: 'PUT',
      url: `api/v1/firmware/devices/${envId}`,
      data: formData,
    })
  },
}
