import React from 'react'
import { Typography } from '@material-ui/core'
import ResourceLang from '../../resources/Language'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import TreeView from '@material-ui/lab/TreeView'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import TreeItem from '@material-ui/lab/TreeItem'
import { colors } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'left',
    paddingTop: '20px',
    overflow: 'auto',
  },
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },

  button: {
    color: colors.yellow[700],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    letterSpacing: 0,
    '&:hover': {
      color: '#efefef',
    },
  },
}))

const handleSelection = (nodeId, children, ItemSelected) => {
  if (children === undefined) {
    //this means that the user was clicked in the lowest level
    ItemSelected(nodeId)
  }
}

const DeviceMenuItems = ({ menuItems, ItemSelected }) => {
  const classes = useStyles()
  const renderTree = (nodes) => {
    return (
      <TreeItem
        onClick={() => handleSelection(nodes.id, nodes.children, ItemSelected)}
        key={nodes.id}
        nodeId={nodes.id.toString()}
        label={nodes.name}
        className={classes.button}
      >
        {Array.isArray(nodes.children)
          ? nodes.children.map((node) => renderTree(node))
          : null}
      </TreeItem>
    )
  }

  const renderTreeView = () => {
    if (menuItems !== undefined) {
      return (
        <>
          <TreeView
            className={classes.root}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
          >
            {menuItems.map((obj, index) => {
              return renderTree(menuItems[index])
            })}
          </TreeView>
        </>
      )
    } else {
      return (
        <>
          <Typography>{ResourceLang.loading}</Typography>
        </>
      )
    }
  }

  return renderTreeView()
}

DeviceMenuItems.propTypes = {
  props: PropTypes.object.isRequired,
  menuItems: PropTypes.array,
  ItemSelected: PropTypes.func.isRequired,
}

export default DeviceMenuItems
