export function isDTVI(typeDescription){
   
    return (typeDescription === "DTVIg"
            || typeDescription === "DTVIm"
            || typeDescription === "DTVI4g"
            || typeDescription === "DTVI-g PMT2"
            || typeDescription === "DTVI4-g PMT2"
            || typeDescription === "Pole Mount DTVIg"
            || typeDescription === "Pole Mount DTVI4-g");
}

export function isPoleMountedOrDtvi4G(typeDescription){
    return (typeDescription === "DTVI-g PMT2"
            || typeDescription === "DTVI4-g PMT2"
            || typeDescription === "Pole Mount DTVIg"
            || typeDescription === "Pole Mount DTVI4-g"
            || typeDescription === "DTVI4-g");
}

export function isDTE(typeDescription){
    return (typeDescription === "DTE");
}