import React, {Component} from 'react';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import storage from 'redux-persist/lib/storage';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import rootReducer from '../reducers/index';
import Routes from '../../routes/Routes';

const language="pt-PT";

const persistConfig = {
  key: 'root',
  storage: storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(persistedReducer, {}, composeEnhancers(applyMiddleware(thunk)));

export var persistor;
export default class AppProvider extends Component {

  constructor() {
    super()
    this.state = { rehydrated: false }
  }

  componentDidMount(){

    window.addEventListener("click", this.handleInteraction)
    window.addEventListener("mousemove", this.handleInteraction)
    window.addEventListener("keypress", this.handleInteraction)

    persistor = persistStore(store, {}, () => {
      this.setState({ rehydrated: true });
    });

  }

  handleInteraction = () =>{
      localStorage.lastInteraction = new Date().getTime();
  }

  componentWillUnmount(){
    window.removeEventListener("click", this.handleInteraction)
    window.removeEventListener("mousemove", this.handleInteraction)
    window.removeEventListener("keypress", this.handleInteraction)
  }


  render() {
    if(!this.state.rehydrated){
      return (<div>Loading...</div>);
    }
    else{
      return (
        <Provider store={store}>
          <IntlProvider locale={language} >
              <Routes />
          </IntlProvider>
        </Provider>
      )
    }
  }
}

