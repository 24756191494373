import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ResourceLang from '../../../resources/Language';
import {FirmwareAPI} from '../../../utils/APIRequester';
import { OvalButtonPrimary, useStyles as ovalByttonsStyleBase } from '../../../components/buttons/OvalButtons';
import { CircularButtonTransparent } from '../../../components/buttons/CircularButtons';
import { makeStyles } from '@material-ui/core/styles';
import { Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignal } from '@fortawesome/free-solid-svg-icons';
import {SETTINGS_TYPE_ENUM }  from '../../../utils/Enums';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/NotInterested';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import DeviceSettingsDialog from '../../../components/dialogs/add/DeviceSettingsDialog';
import MessageSnackbar from '../../../components/notifications/MessageSnackbar';


const useStyles = makeStyles((theme) => ({
    paper: {
       padding: theme.spacing(3),
       margin: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(1),
        float:'right'
    },
    label_title: {
        color: "#94A3A7",
        fontWeight: 'bold',
        marginBottom: theme.spacing(0)
    },
    divider:{
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    }
}));


const NTPSettings = ({
    envId, interfaceNTP, deviceItemId
}) => {
    const classes = useStyles();
    const ovalButtonsStyle = ovalByttonsStyleBase();
    const [editMode, setEditMode] = useState(false);
    const [openApplyToDialog, setApplyToDialog] = useState(false);
    const [data, setData] = useState(interfaceNTP);
    const [ snackbar, setSnackbar] = useState({ open: false, variant: 'error', message: ''});
    
    const readOnly = editMode ? '' : 'readonly';
    
    const handleEditClick = () => {
        setEditMode(!editMode);
    }

    const renderPanelHeader = () => {
        return (<Row>
            <Col>
                <Typography 
                    variant="subtitle1" 
                    gutterBottom
                    className={classes.label_title}>
                        {ResourceLang.ntp_settings} <FontAwesomeIcon icon={faSignal} />
                </Typography>
            </Col>
            <Col style={{textAlign: 'end'}}>
                <Tooltip title="Edit" placement="top">
                    <CircularButtonTransparent size="small" aria-label="edit" onClick={handleEditClick}>
                        <EditIcon />
                    </CircularButtonTransparent>
                </Tooltip>
            </Col>
        </Row>);
    }

    const renderTimeServerType = () => {
        const TIMESERVER_OPTIONS = [
            {label: ResourceLang.ntp, value: 0},
            {label: ResourceLang.htp, value: 1},
            {label: ResourceLang.deepgrid, value: 2}
        ]

        if(editMode) {
            return(<Input 
                bsSize="sm"
                type="select"
                value={data.timeserver_type}
                onChange={event =>{
                    const value = parseInt(event.target.value, 10);
                    setData({...data, timeserver_type: value});
                }}>
                {
                    TIMESERVER_OPTIONS.map(({value, label}) => 
                        <option key={value} value={value}>{label}</option>    
                    )
                }
            </Input>);
        }
        else{
            let value = ResourceLang.NONE;
            if (data.timeserver_type !== undefined) {
                const target = TIMESERVER_OPTIONS.find(x => x.value === data.timeserver_type);
                if (target)
                    value = target.label;
            }
            return(
                <Typography variant="subtitle2" gutterBottom>
                    {value}
                </Typography>
            );
        }
    }

    const renderTimeServerURL = () => {
        if (editMode === true)
            return (
            <Input bsSize="sm" readOnly={readOnly}
                type="text" invalid={!data.timeserver_url}
                value={data.timeserver_url} maxLength="50"
                onChange={(event)=>{
                setData({...data, timeserver_url: event.target.value});
                }}/>
            )
        else{
            if(data.timeserver_url){
                return (
                    <Typography variant="subtitle2" gutterBottom>
                        {data.timeserver_url}
                    </Typography>
                )
            }
            else{
                <Typography variant="subtitle2" gutterBottom>
                    {ResourceLang.not_applicable}
                </Typography>
            }
            
        }
    }

    const renderTimeServerPort = () => {
        if (editMode === true)
            return (
                <Input bsSize="sm" readOnly={readOnly}
                type="text" invalid={!data.timeserver_port}
                value={data.timeserver_port} maxLength="30"
                onChange={(event)=>{
                setData({...data, timeserver_port: event.target.value});
                }}/>
            )
        else {
            if(data.timeserver_port){
                return(
                    <Typography variant="subtitle2" gutterBottom>
                        {data.timeserver_port}
                    </Typography>
                )
            }
            else{
                return(
                    <Typography variant="subtitle2" gutterBottom>
                        {ResourceLang.not_applicable}
                    </Typography>
                )
            }
        }
    }

    const renderTimeServerProtocol = () =>{
        if (editMode === true)
            return (
                <Input bsSize="sm" readOnly={readOnly}
                type="text" invalid={!data.timeserver_protocol}
                value={data.timeserver_protocol} maxLength="30"
                onChange={(event)=>{
                setData({...data, timeserver_protocol: event.target.value});
                }}/>
            )
        else{
            if(data.timeserver_protocol){
                return(
                    <Typography variant="subtitle2" gutterBottom>
                    {data.timeserver_protocol}
                </Typography>
                )
            }
            else{
                return(
                    <Typography variant="subtitle2" gutterBottom>
                    {ResourceLang.not_applicable}
                </Typography>
                )
            }
        } 
    }

    const renderDialogApplyTo = () =>{
        var UpdateData = []
        UpdateData.push(data)
        return(<DeviceSettingsDialog isOpen={openApplyToDialog}
            closeModal={(e) => setApplyToDialog(false)}
            settingsType={SETTINGS_TYPE_ENUM.NTP}
            envId={envId}
            deviceItemId={deviceItemId}
            dataToBeUpdated={UpdateData}/>
        )
    }

    const enableDialogApplyTo = () =>{
        setApplyToDialog(true)
    }

    const renderPanelFields = () => {
        return (<>
            <Row>
                <Col md="3">
                    <FormGroup>
                        <Label size="sm"
                            className={classes.label_title}> 
                                {ResourceLang.timeserver_url} *
                        </Label>
                        {renderTimeServerURL()}
                    </FormGroup>
                </Col>
                <Col md="3">
                    <FormGroup>
                        <Label size="sm"
                            className={classes.label_title}> 
                                {ResourceLang.timeserver_port} *
                        </Label>
                        {renderTimeServerPort()}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
            <Col md="3">
                <FormGroup>
                    <Label size="sm"
                        className={classes.label_title}> 
                            {ResourceLang.timeserver_type} *
                    </Label>
                    {renderTimeServerType()}
                </FormGroup>
                </Col>
                <Col md="3">
                    <FormGroup>
                        <Label size="sm"
                            className={classes.label_title}> 
                                {ResourceLang.timeserver_protocol} *
                        </Label>
                        {renderTimeServerProtocol()}
                    </FormGroup>
                </Col>
                {!editMode ? 
                <Col md="6">
                    <Button variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={enableDialogApplyTo}>
                        {ResourceLang.apply_to}
                    </Button>
                </Col> : null}
            </Row>
            
        </>)
    }

    const handleSnackbarClose = () =>{
        setSnackbar((prevState) => ({...prevState, 
            open: false, 
        }));
    }

    const renderSnackbar = () => {
        return (<MessageSnackbar open={snackbar.open}
            message={snackbar.message}
            onClose={handleSnackbarClose}
            variant={snackbar.variant}
        />);
      }

    const renderButtons = () => {
        if(editMode === false)
            return null;
        
        return(<Row>
            <Col>
                <OvalButtonPrimary size="medium"
                    variant="extended"
                    color="primary"
                    aria-label="add"
                    className={ovalButtonsStyle.submitButton}
                    onClick={() => {
                        const formData = new FormData();
                        formData.append('ntp', JSON.stringify(data));
                        FirmwareAPI.putNTPSettingsByDevice(envId, deviceItemId ,formData)
                        .then(response => {
                            setEditMode(false);
                            setSnackbar((prevState) => ({...prevState, 
                                open: true,
                                variant: "success",
                                message: ResourceLang.update_with_success
                            }));
                        }).catch(error => {
                            setEditMode(true);
                            setSnackbar((prevState) => ({...prevState, 
                                open: true, 
                                variant: "error",
                                message: ResourceLang.generic_error
                            }));
                        })
                    }}>
                <CheckIcon className={ovalButtonsStyle.extendedIcon} />
                    {ResourceLang.submit}
                </OvalButtonPrimary>
                <OvalButtonPrimary size="medium"
                    variant="extended"
                    color="primary"
                    aria-label="add"
                    className={ovalButtonsStyle.cancelButton}
                    onClick={() => {
                        setData({...interfaceNTP});
                        setEditMode(false);
                    }}>
                        <CancelIcon className={ovalButtonsStyle.extendedIcon} />
                        {ResourceLang.cancel}
                </OvalButtonPrimary>
            </Col>
        </Row>);
    }


    return(<Paper elevation={3}
        className={classes.paper}>
            {renderPanelHeader()}
            <Divider className={classes.divider}/>
            {renderPanelFields()}
            {renderButtons()}
            {renderDialogApplyTo()}
            {renderSnackbar()}
    </Paper>);
}


NTPSettings.propTypes = {
    interfaceNTP: PropTypes.shape({
        timeserver_url: PropTypes.string.isRequired,
        timeserver_port: PropTypes.number.isRequired,
        timeserver_protocol: PropTypes.string.isRequired,
        timeserver_type: PropTypes.number.isRequired
    }).isRequired
}

export default NTPSettings;