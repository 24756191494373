import React , {useState, useEffect} from 'react'
import { withRouter } from "react-router";
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import ResourceLang from '../../resources/Language';
import MemoryIcon from '@material-ui/icons/Memory';
import {FirmwareAPI} from '../../utils/APIRequester';
import PageLoading from '../../components/loadings/PageLoading';
import { handleBodyResize } from '../../utils/BrowserServices';
import { Paper, Grid, Typography } from '@material-ui/core';
import APNSettings from './settings/APNSettingsPage';
import NTPSettings from './settings/NTPSettingsPage'; 

const useStyles = makeStyles((theme) => ({
  root: {
      width: '100%',
      height: '100%',
  },
  paper: {
      width: '100%',
      height: '100%',
      overflow: 'auto'
  },
  button: {
    margin: theme.spacing(1),
    float:'right'
  },
  headerTitle: {
      flexGrow: 1,
      marginLeft: theme.spacing(2)
  },
  tableContainer: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
  },
  toolbar: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(1),
  },
  chip: {
      margin: theme.spacing(0.5),
  },
}));


const EditDevicePage = ({match, location, history, ...props}) => {
  const classes = useStyles();
  const [state, setState] = useState({
      selected: undefined,
      deviceItem: undefined
  })

  const envId = match.params.envId;
  console.log(match.params)
  const deviceId = match.params.deviceId;
  
  useEffect(() => {
    if(!state.deviceItem)
    {
        FirmwareAPI.getGPRSSettingsByDevice(envId, deviceId).then(response => {
          setState((prevState) => ({
                        ...prevState,
                        deviceItem: response
                    }));
                  }).catch(error => {
                setState((prevState) => ({
                    ...prevState, deviceItem: []
                }));
            })
    }

    window.addEventListener('resize', handleBodyResize, true);
    return () => {
        window.removeEventListener('resize', handleBodyResize, true);
    }
  }, [state.deviceItem, deviceId, envId]);

  handleBodyResize();
  const HEADER_HEIGHT = 64;
  const TOP_OFFSET = HEADER_HEIGHT;
  const renderPageHeader = () => {
    if(state.deviceItem !== undefined && state.deviceItem.devices)
        return(
        <Toolbar>
          <MemoryIcon color="primary" fontSize="large"/>
          <Typography variant="subtitle1" className={classes.headerTitle}>
            <strong>{ResourceLang.device} - {state.deviceItem.devices[0].serial_number}</strong>
          </Typography>
      </Toolbar>
      );
  }

  function getInterfaceGprs(interfaceItem){
    var interface_gprs={}
    interface_gprs.apn_name = interfaceItem.apn_name
    interface_gprs.apn_auth_type = interfaceItem.apn_auth_type
    interface_gprs.apn_user = interfaceItem.apn_user
    interface_gprs.apn_pwd = interfaceItem.apn_pwd
    interface_gprs.iccid = interfaceItem.iccid
    interface_gprs.current_ip = interfaceItem.current_ip
    return interface_gprs
  }

  function getInterfaceNTP(interfaceItem){
    var interface_ntp = {}
    interface_ntp.timeserver_url = interfaceItem.timeserver_url
    interface_ntp.timeserver_port = interfaceItem.timeserver_port
    interface_ntp.timeserver_protocol = interfaceItem.timeserver_protocol
    interface_ntp.timeserver_type = interfaceItem.timeserver_type
    return interface_ntp
  }

  const renderSettings = () =>{
    if(state.deviceItem !== undefined) {
        return(
            <Grid item xs={12} className={classes.tableContainer}>
                <APNSettings
                interfaceGPRS={getInterfaceGprs(state.deviceItem.devices[0].interface_gprs)}
                envId={envId}
                deviceItemId={deviceId}
                />
                <NTPSettings
                interfaceNTP={getInterfaceNTP(state.deviceItem.devices[0].interface_gprs)}
                envId={envId}
                deviceItemId={deviceId}
                />
            </Grid>
        )
    }
    else{
        return<></>
    }
  }

  return(<div className={classes.root} id="container">
      <Paper className={classes.paper} >
        <Grid container>
            <Grid item xs={12}>
                {renderPageHeader()}
            </Grid>
        </Grid>
        <Grid container style={{height: `calc(100% - ${TOP_OFFSET}px)`}}>
            {renderSettings()}
        </Grid>
      </Paper>
      {!state.deviceItem ? <PageLoading /> : null}
  </div>);

}

export default withRouter(EditDevicePage);
