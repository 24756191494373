import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/core/styles'
import ResourceLang from '../../resources/Language'
import MemoryIcon from '@material-ui/icons/Memory'
import { FirmwareAPI } from '../../utils/APIRequester'
import Tooltip from '@material-ui/core/Tooltip'
import PageLoading from '../../components/loadings/PageLoading'
import { handleBodyResize, compareValues } from '../../utils/BrowserServices'
import { SortDirection } from 'react-virtualized'
import { Paper, Grid, IconButton, Chip, Typography } from '@material-ui/core'
import MuiPaginatedVirtualizedTable from '../../components/tables/MuiPaginatedVirtualizedTable'
import FilterListIcon from '@material-ui/icons/FilterList'
import FilterPopover from '../../components/filters/FilterPopover'
import { DEVICE_VERSION_VALUES_ENUM } from '../../utils/Enums'
import ExcelIcon from '../../components/icons/ExcelIcon'
import { ButtonBlue } from '../../components/buttons/RectanguleButton'
import { useDebounce } from 'use-debounce'
import { isPoleMountedOrDtvi4G } from '../../utils/DeviceServices';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  paper: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
  },
  headerTitle: {
    flexGrow: 1,
    marginLeft: theme.spacing(2),
  },
  tableContainer: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  toolbar: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}))

const ListDevicePage = ({ match, location, history, ...props }) => {
  const classes = useStyles()
  const DEVICE_VERSION_POSITION = 11
  const [anchorEl, updateAnchorEl] = useState(null)
  const [state, setState] = useState({
    selected: undefined,
    deviceItems: undefined,
    currentVersions: undefined,
  })

  const [pageError, setPageError] = useState(false)
  const [pageSize, setPageSize] = useState(50)
  const [totalRows, setTotalRows] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [debouncedCurrentPage] = useDebounce(currentPage, 500)
  const [firmwareIds, setFirmwareIds] = useState(null)
  const [currentVersions, setCurrentVersions] = useState(null)
  const [selectedFirmware, setSelectedFirmware] = useState(null)
  const [selectedVersion, setSelectedVersion] = useState(null)
  const [selectedSerial, setSelectedSerial] = useState(null)
  const [debouncedselectedSerial] = useDebounce(selectedSerial, 500)
  const [isLoading, setIsLoading] = useState(true)

  const [filters, setFilters] = useState([
    {
      key: 'serial_number',
      label: ResourceLang.serial_number,
      type: 'text',
    },
  ])

  const [sort, setSort] = useState({
    sortBy: 'created',
    sortDirection: SortDirection.ASC,
  })

  const envId = match.params.envId

  useEffect(() => {
    function formatVersion(deviceItem) {
      deviceItem.formattedMSP = allEqual(deviceItem.MSP, '0')
        ? '0'
        : deviceItem.MSP.replace(/\b0+/g, '')

      deviceItem.formattedPIC = allEqual(deviceItem.PIC, '0')
        ? '0'
        : deviceItem.PIC.replace(/\b0+/g, '')

      deviceItem.formattedMEA = allEqual(deviceItem.MEA, '0')
        ? '0'
        : deviceItem.MEA.replace(/\b0+/g, '')

      deviceItem.formattedCOM = allEqual(deviceItem.COM, '0')
        ? '0'
        : deviceItem.COM.replace(/\b0+/g, '')
    }

    function setFirmwareData(deviceItem) {
      deviceItem.MSP = 'N/A'
      deviceItem.PIC = 'N/A'
      deviceItem.MEA = 'N/A'
      deviceItem.COM = 'N/A'

      if (deviceItem.device_firmware != null) {
        deviceItem.device_firmware.forEach((firmware) => {
          switch (firmware.firmware_id) {
            case 'MSP':
              deviceItem.MSP = firmware.current_firmware_version
              break
            case 'PIC':
              deviceItem.PIC = firmware.current_firmware_version
              break
            case 'DTE':
              deviceItem.PIC = firmware.current_firmware_version
              break
            case 'MEA':
              deviceItem.MEA = firmware.current_firmware_version
              break
            case 'COM':
              deviceItem.COM = firmware.current_firmware_version
              break
            default:
              break
          }
        })
      }

      formatVersion(deviceItem)
    }

    function setFamilyVersion(deviceItem) {
      if (deviceItem.serial_number.length > DEVICE_VERSION_POSITION) {
        let version = parseInt(
          deviceItem.serial_number[DEVICE_VERSION_POSITION]
        )
        let typeDescription = deviceItem.device_type_description;

        if (isNaN(version)) {
          deviceItem.family_version = ResourceLang.not_applicable
        } else {
          deviceItem.family_version = translateVersion(typeDescription, version)
        }
      } else {
        deviceItem.family_version = ResourceLang.not_applicable
      }
    }

    function setSIMCardData(deviceItem) {
      if (deviceItem.interface_gprs.iccid == null) {
        deviceItem.iccid = ''
      } else {
        deviceItem.iccid = deviceItem.interface_gprs.iccid
      }

      if (deviceItem.interface_gprs.current_ip == null) {
        deviceItem.current_ip = ''
      } else {
        deviceItem.current_ip = deviceItem.interface_gprs.current_ip
      }
    }

    if (state.deviceItems) {
      state.deviceItems.forEach((device) => {
        setSIMCardData(device)
        setFamilyVersion(device)
        setFirmwareData(device)
      })
    }
  }, [state.deviceItems])

  useEffect(() => {
    function setFirmwareOptionIds(firmware_ids) {
      let ids = []
      firmware_ids.forEach((firmware_id) => {
        let id = {
          name: firmware_id,
          value: firmware_id,
        }
        if (uniqueAdd(ids, id)) ids.push(id)
      })

      setFirmwareIds(stringArraySort(ids))
    }

    if (!state.deviceItems) {
      let data = {}
      data.pageSize = pageSize
      data.orderBy = null

      setIsLoading(true)

      FirmwareAPI.getAllDevices(envId, data)
        .then((response) => {
          setState((prevState) => ({
            ...prevState,
            deviceItems: response.devices,
            currentVersions: response.firmware_versions,
          }))
          setTotalRows(response.total_devices)
          setFirmwareOptionIds(response.firmware_ids)
        })
        .catch((error) => {
          setState((prevState) => ({
            ...prevState,
            deviceItems: [],
          }))
        })
        .finally(() => {
          setIsLoading(false)
        })
    }

    window.addEventListener('resize', handleBodyResize, true)
    return () => {
      window.removeEventListener('resize', handleBodyResize, true)
    }
  }, [state.deviceItems, envId, pageSize])

  useEffect(() => {
    let addFilter = true

    filters.forEach((filter) => {
      if (filter.key === 'firmware_id' || firmwareIds == null) {
        addFilter = false
      }
    })

    if (addFilter === true) {
      let filter = {
        key: 'firmware_id',
        label: ResourceLang.firmware_id,
        type: 'select',
        options: firmwareIds,
      }
      setFilters([...filters, filter])
    }
  }, [firmwareIds, filters])

  useEffect(() => {
    function getFormatedVersion(version) {
      return allEqual(version, '0') ? '0' : version.replace(/\b0+/g, '')
    }

    function uniqueAdd(array, object) {
      for (const index in array) {
        if (array[index].name === object.name) return false
      }
      return true
    }

    function setCurrentVersionOptions() {
      let ids = []

      if (state.currentVersions) {
        state.currentVersions.forEach((firmware_id) => {
          let id = {
            name: getFormatedVersion(firmware_id),
            value: firmware_id,
          }
          if (uniqueAdd(ids, id)) ids.push(id)
        })

        setCurrentVersions(stringArraySort(ids))
      }
    }

    setCurrentVersionOptions()
  }, [selectedFirmware, state.deviceItems, state.currentVersions])

  useEffect(() => {
    let addFilter = true
    let removeFilter = false

    if (
      selectedFirmware == null &&
      filters.find((filter) => filter.key === 'current_firmware_version')
    ) {
      removeFilter = true
    }

    let serialFilter = filters.find((filter) => filter.key === 'serial_number')
    if (serialFilter.value === '') {
      setCurrentPage(0)
      setPageSize(50)
      setSelectedSerial(null)
    }

    if (
      filters.find((filter) => filter.key === 'current_firmware_version') ||
      currentVersions == null ||
      currentVersions.length === 0 ||
      removeFilter === true ||
      selectedFirmware == null
    ) {
      addFilter = false
    }

    let filter = {
      key: 'current_firmware_version',
      label: ResourceLang.current_version,
      type: 'select',
      options: currentVersions,
    }

    if (addFilter === true && removeFilter === false) {
      setFilters([...filters, filter])
    }

    if (removeFilter === true) {
      setFilters(
        filters.filter(function (x) {
          return x.key !== 'current_firmware_version'
        })
      )
    }
  }, [currentVersions, selectedFirmware, filters])

  useEffect(() => {
    let data = {}
    data.serialNumber = debouncedselectedSerial
    data.firmwareId = selectedFirmware
    data.firmwareVersion = selectedVersion
    data.pageSize = pageSize
    data.toSkip = debouncedCurrentPage * pageSize
    data.orderBy = null

    setIsLoading(true)

    FirmwareAPI.getAllDevices(envId, data)
      .then((response) => {
        setState((prevState) => ({
          ...prevState,
          deviceItems: response.devices,
          currentVersions: response.firmware_versions,
        }))
        setTotalRows(response.total_devices)
      })
      .catch((error) => {
        setState((prevState) => ({
          ...prevState,
          deviceItems: [],
        }))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [
    selectedFirmware,
    debouncedselectedSerial,
    selectedVersion,
    pageSize,
    envId,
    debouncedCurrentPage,
  ])

   function translateVersion(typeDescription, version) {
        if (isPoleMountedOrDtvi4G(typeDescription) === true) {
            return translatePoleMountVersion(version);
        } else {
            return translateDTVIVersion(version);
        }
    }

    function translatePoleMountVersion(version) {
        if (version === 1 || version === 2) {
            return DEVICE_VERSION_VALUES_ENUM.V1
        }
        else {
            return ResourceLang.not_applicable;
        }
    }

    function translateDTVIVersion(version) {

        if (version === 1) {
            return DEVICE_VERSION_VALUES_ENUM.V1
        }
        else if (version === 2) {
            return DEVICE_VERSION_VALUES_ENUM.V2
        }
        else if (version === 3 || version === 4) {
            return DEVICE_VERSION_VALUES_ENUM.V3
        }
        else if (version === 5 || version === 6) {
            return DEVICE_VERSION_VALUES_ENUM.V4
        }
        else {
            return ResourceLang.not_applicable
        }
  }

  function uniqueAdd(array, object) {
    for (const index in array) {
      if (
        array[index].value === object.value &&
        array[index].name === object.name
      )
        return false
    }
    return true
  }

  function allEqual(string, character) {
    return string.split('').every((char) => char === character)
  }

  function stringArraySort(array) {
    return array.sort(function (a, b) {
      return a.name.localeCompare(b.name)
    })
  }

  function exportCSVFile(deviceItems) {
    if (deviceItems !== null) {
      const formData = new FormData()
      formData.append('devices', JSON.stringify(deviceItems))
      FirmwareAPI.getCSVExportFile(formData)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response]), {
            type: 'text/csv',
          })
          const link = document.createElement('a')
          link.href = url
          link.target = '_blank'
          link.download = 'export_devices.csv'
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const renderPageHeader = () => {
    return (
      <Toolbar>
        <MemoryIcon color='primary' fontSize='large' />
        <Typography variant='subtitle1' className={classes.headerTitle}>
          <strong>{ResourceLang.list_device}</strong>
        </Typography>
        {renderFilterButton()}
        {renderExportButton()}
      </Toolbar>
    )
  }

  handleBodyResize()

  const renderExportButton = () => {
    return (
      <>
        <ButtonBlue
          id='add_button'
          aria-controls='menu-add-device'
          aria-haspopup='true'
          type='submit'
          onClick={() => {
            exportCSVFile(getTableItems())
          }}
        >
          <ExcelIcon />
          {ResourceLang.export}
        </ButtonBlue>
      </>
    )
  }

  const renderFilterButton = () => {
    return (
      <>
        <Tooltip title={ResourceLang.filters} aria-label='filter'>
          <IconButton
            id='btn_filter'
            onClick={(event) => updateAnchorEl(event.currentTarget)}
          >
            <FilterListIcon />
          </IconButton>
        </Tooltip>
        <FilterPopover
          filters={filters}
          anchorEl={anchorEl}
          updateAnchorEl={updateAnchorEl}
          onChange={setFilters}
        />
      </>
    )
  }

  const renderFilters = (filterValues) => {
    return (
      <Grid container style={{ overflowX: 'auto', overflowY: 'hidden' }}>
        <Grid
          item
          xs={12}
          style={{ height: 'auto', marginLeft: 20, minWidth: 'max-content' }}
        >
          {filterValues.map((item, index) => {
            return (
              <Chip
                id={`"chip_${item.key}_${item.value}`}
                key={index}
                label={item.name}
                onDelete={() => {
                  const newFilters = [...filters]
                  const targetKey = newFilters.find((x) => x.key === item.key)
                  if (targetKey.value instanceof Array) {
                    targetKey.value = targetKey.value.filter(
                      (x) => x.value !== item.value
                    )
                  } else {
                    targetKey.value = null
                  }
                  setFilters(newFilters)
                }}
                className={classes.chip}
              />
            )
          })}
        </Grid>
      </Grid>
    )
  }

  const getFilterValuesArray = () => {
    const filterValues = []
    for (let i = 0; i < filters.length; i++) {
      if (filters[i].value instanceof Array) {
        if (filters[i].value.length > 0) {
          for (let j = 0; j < filters[i].value.length; j++) {
            if (
              filters[i].key === 'firmware_id' &&
              selectedFirmware !== filters[i].value[j].value
            ) {
              setCurrentPage(0)
              setPageSize(50)
              setSelectedFirmware(filters[i].value[j].value)
            } else if (
              filters[i].key === 'current_firmware_version' &&
              selectedVersion !== filters[i].value[j].value &&
              selectedFirmware !== null
            ) {
              setCurrentPage(0)
              setPageSize(50)
              setSelectedVersion(filters[i].value[j].value)
            }

            filterValues.push({
              ...filters[i].value[j],
              key: filters[i].key,
              type: filters[i].type,
            })
          }
        } else {
          if (filters[i].key === 'firmware_id' && selectedFirmware !== null) {
            setCurrentPage(0)
            setPageSize(50)
            setSelectedVersion(null)
            setSelectedFirmware(null)
          } else if (
            filters[i].key === 'current_firmware_version' &&
            selectedVersion !== null
          ) {
            setCurrentPage(0)
            setPageSize(50)
            setSelectedVersion(null)
          }
        }
      } else if (filters[i].value) {
        let name = filters[i].value

        if (filters[i].value instanceof Date)
          name = `${filters[i].label}: ${name.toLocaleDateString()}`

        if (
          filters[i].key === 'serial_number' &&
          selectedSerial !== filters[i].value
        ) {
          setCurrentPage(0)
          setPageSize(50)
          setSelectedSerial(filters[i].value)
        }

        filterValues.push({
          name: name,
          value: filters[i].value,
          key: filters[i].key,
          type: filters[i].type,
        })
      }
    }
    return filterValues
  }

  const renderTable = () => {
    const { deviceItems } = state
    if (!deviceItems) {
      return null
    }

    const columns = [
      {
        columnWidth: 50,
        label: '#',
        dataKey: 'device_id',
        align: 'left',
        disableSort: true,
      },
      {
        columnWidth: 150,
        label: ResourceLang.type,
        dataKey: 'device_type_description',
      },
      {
        columnWidth: 200,
        label: ResourceLang.hardware_version,
        dataKey: 'family_version',
      },
      {
        columnWidth: 225,
        label: ResourceLang.serial_number,
        dataKey: 'serial_number',
      },
      {
        columnWidth: 150,
        label: ResourceLang.ICCID,
        dataKey: 'iccid',
      },
      {
        columnWidth: 150,
        label: ResourceLang.CurrentIP,
        dataKey: 'current_ip',
      },
      {
        columnWidth: 125,
        label: ResourceLang.MSP,
        dataKey: 'formattedMSP',
      },
      {
        columnWidth: 125,
        label: ResourceLang.PIC,
        dataKey: 'formattedPIC',
      },
      {
        columnWidth: 125,
        label: ResourceLang.MEA,
        dataKey: 'formattedMEA',
      },
      {
        columnWidth: 125,
        label: ResourceLang.COM,
        dataKey: 'formattedCOM',
      },
      {
        columnWidth: 150,
        label: ResourceLang.last_communication,
        dataKey: 'last_contact',
        date: true,
      },
    ]

    const tableItems = getTableItems()

    const rowGetter = ({ index }) => {
      return tableItems[index]
    }

    const handleSort = (event) => {
      setSort({
        sortBy: event.sortBy,
        sortDirection: event.sortDirection,
      })
    }

    const onGoToPage = (event) => {
      let totalPages = Math.ceil(totalRows / pageSize)

      if (
        !event.target.value.includes('NaN') &&
        totalPages >= event.target.value
      ) {
        setPageError(false)
        setCurrentPage(event.target.value)
      } else {
        setPageError(true)
        setCurrentPage(null)
      }
    }

    function onChangePage(event, page) {
      setCurrentPage(page)
    }

    function onRowsPerPageChange(object) {
      setCurrentPage(0)
      setPageSize(object.target.value)
    }

    function getPagination() {
      let pagination = {}
      pagination.count = totalRows
      pagination.onChangePage = onChangePage
      pagination.page =
        debouncedCurrentPage == null ? 0 : parseInt(debouncedCurrentPage)
      pagination.rowsPerPage = pageSize
      pagination.onChangeRowsPerPage = onRowsPerPageChange
      pagination.rowsPerPageOptions = [10, 25, 50, 100, totalRows]
      return pagination
    }

    return (
      <MuiPaginatedVirtualizedTable
        pagination={getPagination()}
        labelPage={currentPage == null ? '' : currentPage.toString()}
        totalRows={totalRows}
        rowCount={tableItems.length}
        rowGetter={rowGetter}
        pageError={pageError}
        sort={handleSort}
        sortBy={sort.sortBy}
        sortDirection={sort.sortDirection}
        columns={columns}
        iconsMap={[]}
        onGoToPage={onGoToPage}
        onRowClick={({ event, index, rowData }) => {
          setState((prevState) => ({ ...prevState, selected: rowData }))
          history.push(
            '/environment/' +
              envId +
              '/device/' +
              rowData.device_id +
              '/summary',
            { context: location.state.context }
          )
        }}
      />
    )
  }

  const filterValues = getFilterValuesArray()

  const getTableItems = () => {
    let results = [...state.deviceItems]

    if (sort.sortDirection === SortDirection.ASC) {
      results.sort((a, b) => compareValues(b[sort.sortBy], a[sort.sortBy]))
    } else {
      results.sort((a, b) => compareValues(a[sort.sortBy], b[sort.sortBy]))
    }

    return results
  }

  const hasFilters = filterValues.length > 0
  const HEADER_HEIGHT = 64
  const FILTERS_HEIGHT = 40
  const TOP_OFFSET = HEADER_HEIGHT + (hasFilters ? FILTERS_HEIGHT : 0)

  return (
    <div className={classes.root} id='container'>
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item xs={12}>
            {renderPageHeader()}
          </Grid>
          {hasFilters ? (
            <Grid item xs={12} style={{ height: FILTERS_HEIGHT }}>
              {renderFilters(filterValues)}
            </Grid>
          ) : null}
        </Grid>
        <Grid container style={{ height: `calc(100% - ${TOP_OFFSET}px)` }}>
          <Grid item xs={12} className={classes.tableContainer}>
            {renderTable()}
          </Grid>
        </Grid>
      </Paper>
      {isLoading ? <PageLoading /> : null}
    </div>
  )
}

export default withRouter(ListDevicePage)