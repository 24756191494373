export function setLoggedUser(isLoggedIn) {
    return {
        type: 'CHANGE_USER_STATUS',
        isLoggedIn
    };
}

export function SetUserData(user){
    return {
        type: 'USER_DATA',
        user
    };
}