import React, { useState } from 'react';
import PropTypes from "prop-types";
import { TextField } from '@material-ui/core';


const MuiInputText = ({
    name, value = "", label, required = false, minLength = 1, maxLength = 4294967295, 
    error = false, validate = undefined, onChange = undefined, ...props
}) => {
    const [inputValue, setInputValue] = useState(value);

    const handleOnChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleOnBlur = (event) => {
        if (onChange && inputValue !== value)
            onChange(name, inputValue, isError(inputValue));
    }


    const isError = (value) => {
        if (validate) {
            return !validate(value);
        }
        else if (required) {
            return !value || !validateRange(value);
        }
        else {
            return value && !validateRange(value);
        }
    }

    const validateRange = (value) => {
        return value.length >= minLength && value.length <= maxLength;
    }

    
    return <TextField {...props}
        name={name}
        fullWidth
        label={label}
        value={inputValue}
        error={error || isError(inputValue)}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        inputProps={{
            minLength: minLength,
            maxLength: maxLength,
            required: required
        }}
        InputLabelProps={{
            required: required
        }}
    />
}


MuiInputText.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    error: PropTypes.bool,
    validate: PropTypes.func,
    onChange: PropTypes.func
}

export default MuiInputText;