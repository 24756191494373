import React from 'react';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    margin: {
      margin: theme.spacing(1),
      display: 'inline', float: 'right',
      backgroundColor: '#f3f3f4',
      border: 'solid 1px #F1C419',
      color: '#2C4153',
      fontSize: 12,
      fontWeight: 'bold',
      '&:hover':{
          backgroundColor: '#F1C419',
          color: 'white'
      }
    },
    extendedIcon: {
        fontSize:16,
        marginRight: theme.spacing(1),
    },
    submitButton: {
      margin: theme.spacing(1),
      backgroundColor: '#2C4153',
      border: 'solid 1px #F1C419',
      color: '#white',
      fontSize: 12,
      fontWeight: 'bold',
      '&:hover':{
          backgroundColor: '#F1C419',
          color: 'white'
      }
    },
    cancelButton: {
      margin: theme.spacing(1),
      backgroundColor: '#f3f3f4',
      border: 'solid 1px #F1C419',
      color: '#2C4153',
      fontSize: 12,
      fontWeight: 'bold',
      '&:hover':{
          backgroundColor: '#F1C419',
          color: 'white'
      }
    },
  }));


  export  const OvalButtonPrimary = props =>{
      return(
          <Fab {...props}>

              {props.children}
          </Fab>
      )
  }
