import React from 'react';
import ResourceLang from '../../../resources/Language';
import PropTypes from 'prop-types';
import { Grid, InputLabel } from '@material-ui/core';
import RawVirtualizedGrid from '../../tables/RawVirtualizedGrid';
import cloneDeep from 'lodash/cloneDeep';

const UpdateAPNSettings = ({devices, dataToBeUpdated}) => {

    const DeviceKeysEnum = {
        SERIAL_NUMBER: 'serial_number'
    }

    const DEVICE_SETTINGS_KEYS = {
        APN_NAME: 'apn_name',
        APN_USER:'apn_user',
        APN_PWD: 'apn_pwd',
        APN_AUTH_TYPE: 'apn_auth_type' 
    }

    const APN_SETTINGS_INDEX = {
        PAP: 1,
        CHAP: 2,
        PAP_OR_CHAP: 3
    }

    function translateApnAuthType(translatedSettings){
        if(translatedSettings.apn_auth_type === APN_SETTINGS_INDEX.PAP){
            translatedSettings.apn_auth_type = ResourceLang.PAP
        }
        else if (translatedSettings.apn_auth_type === APN_SETTINGS_INDEX.CHAP)
        {
            translatedSettings.apn_auth_type = ResourceLang.CHAP
        }
        else if (translatedSettings.apn_auth_type === APN_SETTINGS_INDEX.PAP_OR_CHAP)
        {
            translatedSettings.apn_auth_type = ResourceLang.PAP_OR_CHAP
        }
    }
  
    function translatePwd(translatedSettings){
        let password_chars="";
        for(let index = 0;index<translatedSettings.apn_pwd.length;index++)
        {
            password_chars = password_chars + '*';
        }
        translatedSettings.apn_pwd = password_chars;
    }

      function translateSettingsToTableRender(translatedSettings){
        if(translatedSettings.apn_auth_type !== undefined){
          translateApnAuthType(translatedSettings)
        }
        if(translatedSettings.apn_pwd !== undefined){
          translatePwd(translatedSettings)
        }
      }

    const translatedSettings = cloneDeep(dataToBeUpdated);

    const renderContent = () => {
        return (<Grid container spacing={2} style={{marginTop: 8}}>
            <Grid item xs={12} style={{height: 420}}>
                <InputLabel>{ResourceLang.devices}</InputLabel>
                {renderDeviceItems()}
                <InputLabel>{ResourceLang.data_update_apn}</InputLabel>
                {renderApnSettings()}
            </Grid>
            </Grid>
            );
    }

    const renderDeviceItems = () => {
        const gridRowGetter = (index) => { return devices[index]; }
        const keys = Object.keys(devices[0]);
        var columns = keys.map(key => ({label: ResourceLang[key], dataKey:key}))
            .filter(x => x.dataKey === DeviceKeysEnum.SERIAL_NUMBER );
        
        return (<div  data-testid='sdsTable' style={{height: 200, width: '100%', marginTop: 8}}>
            <RawVirtualizedGrid
                rowCount={devices.length}
                rowGetter={gridRowGetter}
                columns={columns}
               />
        </div>);
    }

    const renderApnSettings = () => {
        translateSettingsToTableRender(translatedSettings[0])
        const gridRowGetter = (index) => { return translatedSettings[index]; }
        const columns = [
            {
                columnWidth: 150,
                label: ResourceLang.name,
                dataKey: DEVICE_SETTINGS_KEYS.APN_NAME,
                disableSort: false,
            },
            {
                columnWidth: 75,
                label: ResourceLang.User,
                dataKey: DEVICE_SETTINGS_KEYS.APN_USER,
                disableSort: false,
            },
            {
                columnWidth: 75,
                label: ResourceLang.password,
                dataKey: DEVICE_SETTINGS_KEYS.APN_PWD,
                disableSort: false,
            },
            {
                columnWidth: 100,
                label: ResourceLang.Auth,
                dataKey: DEVICE_SETTINGS_KEYS.APN_AUTH_TYPE,
                disableSort: false
            }
          ];
        return (<div  data-testid='sdsTable' style={{height: 200, width: '100%', marginTop: 8}}>
            <RawVirtualizedGrid
                rowCount={translatedSettings.length}
                rowGetter={gridRowGetter}
                columns={columns}
               />
        </div>);
    }


    return (<>{renderContent()}</>)
}

UpdateAPNSettings.propTypes = {
    devices: PropTypes.array.isRequired,
    dataToBeUpdated: PropTypes.array.isRequired
};

export default UpdateAPNSettings;