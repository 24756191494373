import React from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import LoginForm from '../components/forms/loginform/LoginForm';
import SessionServices from '../utils/SessionServices';
import { connect } from 'react-redux';
import { setLoggedUser, SetUserData} from '../redux/actions/UserActions';

const LoginPage = ({setUserData, setUserinStore, history}) => {
    const login = (result, user, keepLogged) => {  
        if(result === true){
            SessionServices.setAuthContext(user.token, user.name, keepLogged);
            setUserinStore();
            setUserData(user);

            redirectUser();
        }

        return { success: result };
    }
    
    const redirectUser = () => {
        //because firefox save the variable in local storage to fast...  
        setTimeout(() => redirectUserTo(), 100);
    }

    const redirectUserTo = () => {
        const redirectTo = SessionServices.getRedirectTo();
        SessionServices.cleanRedirectTo();

        if (!redirectTo) {
            history.replace("/dashboard")

        }
        else {
            history.replace(redirectTo.substring(1))
        }           
    }

    const renderLogin = () => {
        return(<LoginForm login={(result,value) => login(result,value)}/>)
    }

    return(
        renderLogin()
    );
}

const mapStateToProps = (state) => {
	return {
        userStatus: state.userStatus,
    };
};


const mapDispatchToProps = (dispatch) => {
	return {
        setUserinStore: () => dispatch(setLoggedUser(true)),
        removeUserFromStore: () =>  dispatch(setLoggedUser(false)),
		setUserData:(user) => dispatch(SetUserData(user)),
    };
};

LoginPage.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
}
  

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginPage));
