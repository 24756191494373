
import MuiVirtualizedTable from '../../../components/tables/MuiVirtualizedTable';
import ResourceLang from '../../../resources/Language';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';

const NTPSettings = ({settings}) => {

    const DEVICE_SETTINGS_KEYS = {
        TIMESERVER_URL: 'timeserver_url',
        TIMESERVER_PORT:'timeserver_port',
        TIMESERVER_PROTOCOL: 'timeserver_protocol',
        TIMESERVER_TYPE: 'timeserver_type' 
    }
    const translatedSettings = cloneDeep(settings);

    function translateSettingsToTableRender(settingsToUpdate) {
      if(settingsToUpdate.timeserver_type !== undefined){
        if(settingsToUpdate.timeserver_type === 0){
          settingsToUpdate.timeserver_type = ResourceLang.ntp
        }
        else if (settingsToUpdate.timeserver_type === 1)
        {
          settingsToUpdate.timeserver_type = ResourceLang.htp
        }
        else if (settingsToUpdate.timeserver_type === 2)
        {
          settingsToUpdate.timeserver_type = ResourceLang.deepgrid
        }
      }
    }


    const renderSettingsTable = () => {
        if (translatedSettings !== undefined ) {
          translateSettingsToTableRender(translatedSettings[0])
          const columns = [
            {
              columnWidth: 75,
              label: ResourceLang.timeserver_url,
              dataKey: DEVICE_SETTINGS_KEYS.TIMESERVER_URL,
              disableSort: false,
            },
            {
                columnWidth: 75,
                label: ResourceLang.timeserver_port,
                dataKey: DEVICE_SETTINGS_KEYS.TIMESERVER_PORT,
                disableSort: false,
            },
            {
                columnWidth: 75,
                label: ResourceLang.timeserver_protocol,
                dataKey: DEVICE_SETTINGS_KEYS.TIMESERVER_PROTOCOL,
                disableSort: false,
            },
            {
                columnWidth: 75,
                label: ResourceLang.timeserver_type,
                dataKey: DEVICE_SETTINGS_KEYS.TIMESERVER_TYPE,
                disableSort: false,
            }
          ];
    
          return (<MuiVirtualizedTable
            totalRows={translatedSettings.length}
            rowCount={translatedSettings.length}
            rowGetter={({ index }) => translatedSettings[index]}
            sortBy={DEVICE_SETTINGS_KEYS.TIMESERVER_URL}
            sortDirection={'ASC'}
            columns={columns}
          />);
        }
        else {
          return (<></>)
        }
    
      }

    return(<>{renderSettingsTable()}</>)

}


NTPSettings.propTypes = {
  settings: PropTypes.array.isRequired
};

export default NTPSettings; 