import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router'
import PageHeader from '../components/headers/PageHeader'
import { Typography, Divider, Chip } from '@material-ui/core'
import DeviceMenuItems from '../components/MenuItems/MenuItems'
import { makeStyles } from '@material-ui/core/styles'
import ResourceLang from '../resources/Language'
import { FirmwareAPI } from '../utils/APIRequester'
import MessageSnackbar from '../components/notifications/MessageSnackbar'
import ExtensionIcon from '@material-ui/icons/Extension'
import MemoryIcon from '@material-ui/icons/Memory'
import DevicesOtherIcon from '@material-ui/icons/DevicesOther'
import PageLoading from '../components/loadings/PageLoading'
import UpdateFirmwarePanel from '../components/panels/UpdateFirmwarePanel'
import Grid from '@material-ui/core/Grid'
// import FilterListIcon from '@material-ui/icons/FilterList'
// import FilterPopover from '../components/filters/FilterPopover'
// import Tooltip from '@material-ui/core/Tooltip'
// import { IconButton } from '@material-ui/core'
import {
  translateObjectToMenu,
  findElementById,
} from '../utils/FirmwareServices'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginTop: 1,
    height: '100vh',
  },
  body_info: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(2),
  },
  treeView: {
    backgroundColor: '#2C4153',
    height: '100vh',
    overflow: 'auto',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
  },
  filters: {
    right: 0,
  },
  firmware_panel: {
    height: 400,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  chip: {
    marginRight: 5,
  },
}))

const UpdateFirmwarePage = ({ match, location, history, ...props }) => {
  const classes = useStyles()
  const [MenuItems, setMenuItems] = useState(undefined)
  const [isDisableUpdateFirmwarePanel, setDisableUpdateFirmwarePanel] =
    useState(true)
  const [snackbar, setSnackbar] = useState({
    open: false,
    variant: 'error',
    message: '',
  })
  const [micro_controller, setMicro] = useState(undefined)
  const [device_type_name, setDevice] = useState(undefined)
  const [device_version, setVersion] = useState(undefined)
  //   const [currentVersions, setCurrentVersions] = useState(null)
  //   const [updateVersions, setUpdateVersions] = useState(null)

  //   const [anchorEl, updateAnchorEl] = useState(null)
  //   const [filters, setFilters] = useState([
  //     {
  //       key: 'current_version',
  //       label: ResourceLang.current_version,
  //       type: 'select',
  //       options: currentVersions,
  //     },
  //     {
  //       key: 'update_version',
  //       label: ResourceLang.update_version,
  //       type: 'select',
  //       options: updateVersions,
  //     },
  //   ])

  const envId = match.params.envId

  useEffect(() => {
    if (!MenuItems) {
      FirmwareAPI.getStructure(envId).then((response) => {
        translateObjectToMenu(response)
        setMenuItems(response)
      })
    }
  }, [envId, MenuItems])

  //   const renderFilterButton = () => {
  //     return (
  //       <>
  //         <Tooltip title={ResourceLang.filters} aria-label='filter'>
  //           <IconButton
  //             id='btn_filter'
  //             onClick={(event) => updateAnchorEl(event.currentTarget)}
  //           >
  //             <FilterListIcon />
  //           </IconButton>
  //         </Tooltip>
  //         <FilterPopover
  //           filters={filters}
  //           anchorEl={anchorEl}
  //           updateAnchorEl={updateAnchorEl}
  //           onChange={setFilters}
  //         />
  //       </>
  //     )
  //   }

  const renderDeviceTypeIcon = () => {
    if (device_type_name) {
      return (
        <Chip
          color='primary'
          className={classes.chip}
          icon={<DevicesOtherIcon />}
          label={device_type_name}
        />
      )
    }

    return null
  }

  const renderExtensionIcon = () => {
    if (device_version) {
      return (
        <Chip
          color='primary'
          className={classes.chip}
          icon={<ExtensionIcon />}
          label={device_version}
        />
      )
    }

    return null
  }

  const renderMemoryIcon = () => {
    if (micro_controller) {
      return (
        <Chip
          color='primary'
          className={classes.chip}
          icon={<MemoryIcon />}
          label={micro_controller}
        />
      )
    }

    return null
  }

  const renderUpdateFirmwareButton = () => {
    if (!micro_controller || !device_type_name || !device_version) {
      return null
    }
  }

  const closeUpdateFirmware = (successFromPanel, messageFromPanel) => {
    setDisableUpdateFirmwarePanel(true)
    setSnackbar((prevState) => ({
      ...prevState,
      open: true,
      message: messageFromPanel,
      variant: successFromPanel,
    }))
  }

  const renderFirmwarePanel = () => {
    return (
      <>
        {!isDisableUpdateFirmwarePanel ? (
          <UpdateFirmwarePanel
            deviceTypeName={device_type_name}
            deviceVersion={device_version.replace('v', '')}
            microController={micro_controller}
            closePanel={closeUpdateFirmware}
          />
        ) : null}
      </>
    )
  }

  const setDeviceChoosen = (ItemSelected) => {
    if (ItemSelected) {
      const deviceSelected = findElementById(MenuItems, ItemSelected)
      if (deviceSelected) {
        setDisableUpdateFirmwarePanel(false)
        setDevice(deviceSelected.deviceType)
        setVersion(deviceSelected.version)
        setMicro(deviceSelected.micro)
      }
    }
  }

  const renderHeader = () => {
    return (
      <PageHeader
        title={ResourceLang.update_firmware}
        icon={<MemoryIcon color='primary' fontSize='large' />}
      ></PageHeader>
    )
  }

  const handleSnackbarClose = () => {
    setSnackbar((prevState) => ({ ...prevState, open: false }))
  }

  const renderSnackbar = () => {
    return (
      <MessageSnackbar
        open={snackbar.open}
        message={snackbar.message}
        onClose={handleSnackbarClose}
        variant={snackbar.variant}
      />
    )
  }

  return (
    <div className={classes.root}>
      <Grid item xs={2} className={classes.treeView}>
        <DeviceMenuItems
          props={props}
          menuItems={MenuItems}
          ItemSelected={setDeviceChoosen}
        />
        <Divider />
      </Grid>
      <Grid item className={classes.content}>
        {renderHeader()}
        <br />
        <div className={classes.body_info}>
          <Typography paragraph>
            {ResourceLang.micro_controller_info}
          </Typography>
          {/* TODO: Do not have inline styles */}
          {/* <div
            classname={classes.header}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          > */}
          <b>{ResourceLang.micro_controller} </b>&nbsp;
          {renderDeviceTypeIcon()}
          {renderExtensionIcon()}
          {renderMemoryIcon()}
          {renderUpdateFirmwareButton()}
          {/* <div className={classes.filters} style={{ marginLeft: 'auto' }}>
              {renderFilterButton()}
            </div>
          </div> */}
        </div>
        <div className={classes.firmware_panel}>{renderFirmwarePanel()}</div>
        {renderSnackbar()}
      </Grid>
      {!MenuItems ? <PageLoading /> : null}
    </div>
  )
}

export default withRouter(UpdateFirmwarePage)
