import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles(theme => ({
    blueButton: {
        margin: theme.spacing(1),
        backgroundColor: '#2C4153',
        border: 'solid 1px #F1C419',
        color: 'rgb(241,198,25)',
        fontSize: 12,
        fontWeight: 'bold',
        '&:hover':{
            backgroundColor: 'rgb(241,198,25)',
            color: '#2C4153',
        }
    },
}));

export const ButtonBlue = props =>{
    const {size} = props || 'medium';
    const classes = useStyles();

    return(
        <Button size={size} className={classes.blueButton} {...props}>
            {props.children}
        </Button>
    )
}