import React from 'react'
import { withRouter } from "react-router";
import ResourceLang from '../resources/Language';
import { makeStyles } from '@material-ui/core/styles';
import PageHeader from '../components/headers/PageHeader';
import ApartmentIcon from '@material-ui/icons/Apartment';
import { EnvironmentAPI } from '../utils/APIRequester';
import { Grid, Typography } from '@material-ui/core';
import CircularLoading from '../components/loadings/CircularLoading';
import GenericCard from '../components/cards/GenericCard';
import MemoryIcon from '@material-ui/icons/Memory';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1)
    },
    contentRoot: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    grid: {
        padding: theme.spacing(2)
    },
    cardRoot: {
        backgroundColor: '#f5f5f5'
    },
}));


const EnvironmentPage = ({ match, location, history, ...props }) => {
    const classes = useStyles();
    const [state, setState] = React.useState({
        isLoading: false,
        environment: undefined

    });

    React.useEffect(() => {
        if (!state.environment) {
            setState((prevState) => ({ ...prevState, isLoading: true }));

            EnvironmentAPI.getOne(match.params.envId)
                .then(response => {
                    setState((prevState) => ({
                        ...prevState,
                        isLoading: false,
                        environment: response
                    }));
                })
                .catch(error => {
                    setState((prevState) => ({
                        ...prevState,
                        isLoading: false,
                        environment: undefined,
                    }));
                });
        }
    }, [state.environment, match.params.envId]);

    const handleUploadFirmwareClick  = () => event => {
        history.push(`/environment/${match.params.envId}/uploadfirmware`,
            { context: state.environment.Name });
    }

    const handleUpdateFirmwareClick  = () => event => {
        history.push(`/environment/${match.params.envId}/updatefirmware`,
            { context: state.environment.Name });
    }

    const handleListDeviceClick  = () => event => {
        history.push(`/environment/${match.params.envId}/device`,
            { context: state.environment.Name });
    }


    const renderUploadFirmwareCard = () => {
        return (
            <GenericCard
                element={state.environment}
                hasActions={false}
                handleClick={handleUploadFirmwareClick}>
                    <Typography variant="h6" gutterBottom>
                        <MemoryIcon color="primary"/> {ResourceLang.upload_firmware}
                    </Typography>
            </GenericCard>
        )
    }

    const renderUpdateFirmwareCard = () => {
        return (
            <GenericCard
                element={state.environment}
                hasActions={false}
                handleClick={handleUpdateFirmwareClick}>
                    <Typography variant="h6" gutterBottom>
                        <MemoryIcon color="primary"/> {ResourceLang.update_firmware}
                    </Typography>
            </GenericCard>
        )
    }

    const renderListDeviceCard = () => {
        return (
            <GenericCard
                element={state.environment}
                hasActions={false}
                handleClick={handleListDeviceClick}>
                    <Typography variant="h6" gutterBottom>
                        <MemoryIcon color="primary"/> {ResourceLang.list_device}
                    </Typography>
            </GenericCard>
        )
    }

    const renderHeader = () => {

        if (!state.environment)
            return;

        return (<PageHeader title={state.environment.Name}
            icon={<ApartmentIcon color="primary"
                fontSize="large" />
            }>
        </PageHeader>)
    }

    const renderContent = () => {
        return (
            <Grid container spacing={2}
                className={classes.grid}>
                <Grid item xs={12} sm={6} lg={4}>
                    {renderUploadFirmwareCard()}
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    {renderUpdateFirmwareCard()}
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    {renderListDeviceCard()}
                </Grid>
            </Grid>
        );
    }


    return (<div className={classes.root}>
        <Grid container spacing={1}>
            <Grid item xs={12}>
                {renderHeader()}
            </Grid>
            <Grid item xs={12} className={classes.contentRoot}>
                {state.isLoading ? <CircularLoading /> : renderContent()}
            </Grid>
        </Grid>
    </div>);
}

export default withRouter(EnvironmentPage);